import { Models } from '@core/types';
import { ProjectEnums, CategoryEnums } from '@core/enums';

export enum Method {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export interface IRequest {
  method: Method;
  slug: string;
  params?: any;
  controller: string;
  cacheKey?: string;
  bearerToken?: string;
  baseUrl?: string;
}

export interface IApiError {
  fieldName: string;
  message: string;
}

export interface IBaseResponse<T> {
  data: T | null;
  errors: IApiError[];
  success: boolean;
}

export interface IBaseAuthorisedRequest {
  bearerToken: string;
}

export interface IFetchProjectsRequest extends IBaseAuthorisedRequest {}

export interface IFetchProjectsResponse {
  projects: Models.Project[];
}

export interface IFetchProjectRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
}

export interface IFetchProjectResponse {
  project: Models.Project;
}

export interface ICreateProjectRequest extends IBaseAuthorisedRequest {
  projectName: string;
}

export interface ICreateProjectResponse {
  projectGUID: string;
}

export interface IDeleteProjectRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
}

export interface IFetchProductsRequest extends IBaseAuthorisedRequest {
  cultureCode: string;
  articleNumbers?: string[];
  systems?: CategoryEnums.SystemIDs[];
  variantAgilityIDs?: number[];
  categoryAgilityIDs?: CategoryEnums.AgilityIDs[];
  searchTerm?: string;
}

export interface IFetchProductsResponse {
  categories: Models.Category[];
}

export interface IFetchNavigationResponse {
  cordless: Models.Category | null;
  corded: Models.Category | null;
  m18: Models.Category | null;
  m12: Models.Category | null;
}

export interface IUpdateProjectRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
  projectProducts: Models.ProjectProduct[];
  cultureCode: string;
  refetchProject: boolean;
}

export interface IGeneratePDFRequest extends IBaseAuthorisedRequest {
  projectGUID: string;
  cultureCode: string;
  size: ProjectEnums.PDFSize;
}

export interface IGeneratePDFResponse {
  projectPDF: Models.ProjectPDF;
}

export interface IUpdateQuantity {
  agilityID: number;
  quantity: number;
}

export interface IFetchPendingApprovalsRequest extends IBaseAuthorisedRequest {
  cultureCodes: string[];
}

export interface IFetchPendingApprovalsResponse {
  projectPDFs: Models.ProjectPDF[];
}

export interface IPrinterApproveReject extends IBaseAuthorisedRequest {
  projectPDFID: number;
  acceptReject: boolean;
  cultureCode: string;
}

export interface ISendForApprovalRequest extends IBaseAuthorisedRequest {
  projectPDFID: number;
  cultureCode: string;
}

export interface IFetchTranslationsResponse {
  Localisations: any;
}
