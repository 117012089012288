import React, { Fragment, FunctionComponent } from 'react';
import { Navigation } from '@components/navigation';
import { ProjectList } from '@components/projects';
import { Logo } from '@components/icons';

interface IProps {
}

const Landing: FunctionComponent<IProps> = () => (
  <Fragment>
    <Navigation />
    <main>
      <Logo className="app__bg" />
      <div className="site-wide">
        <ProjectList />
      </div>
    </main>
  </Fragment>
);

export default Landing;
