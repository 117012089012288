import React, { FunctionComponent } from 'react';
import ClassNames from 'classnames';

interface ProductSearchProps {
  active: boolean;
}

const ProductSearch: FunctionComponent<ProductSearchProps> = ({ active }) => {
  const productSearchClassNames = ClassNames({
    'product-search': true,
    'is-active': active,
  });

  return (
    <div className={productSearchClassNames}>
      <div className="product-search__container">
        <div className="product-search__search-container">
          <input
            type="text"
            id="txtSearch"
            placeholder="Search by Product Name or Article Number"
            className="product-search__search"
          ></input>
        </div>
        <div className="product-search__products">
          <div className="product-select__product-item">
            <input
              id="searchChkbox1"
              type="checkbox"
              className="product-select__product-item--input"
            />
            <label htmlFor="searchChkbox1" className="product-select__product-item--label">
              M18 HB12
            </label>
          </div>
        </div>
      </div>
      <div className="product-search__button">
        <button className="btn">Add Products</button>
      </div>
    </div>
  );
};

export default ProductSearch;
