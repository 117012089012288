import React, { useState, FunctionComponent, useEffect } from 'react';

interface Item {
  key: string;
  value: string;
}

interface IProps {
  placeholder?: string;
  onChange: (value: string) => void;
  values: Item[];
  value: string;
}

const Dropdown: FunctionComponent<IProps> = ({ placeholder, values, onChange, value }) => {
  const firstValue = values.find(x => x.key === value);
  const [isOpen, setIsOpen] = useState(false);
  const [_value, setValue] = useState(firstValue?.value || placeholder || '');

  useEffect(() => {
    const body = document.querySelector('body');
    if (!body) {
      return;
    }

    body.addEventListener('click', _close);

    return () => body.removeEventListener('click', _close);
  }, []);

  const _setIsOpen = (e: React.MouseEvent) => {
    e.stopPropagation();

    setIsOpen(!isOpen);
  };

  const _close = () => {
    setIsOpen(false);
  };

  const onChangeInternal = (item: Item) => {
    setValue(item.value);
    onChange(item.key);
    setIsOpen(false);
  };

  return (
    <div className="form__row">
      <div className={`form__control form__dropdown ${isOpen ? 'active' : ''}`}>
        <div className="form__dropdown-selected" onClick={_setIsOpen}>
          {_value}
        </div>
        <div className="form__dropdown-values">
          {values.map(x => (
            <div key={x.key} className="form__dropdown-value" onClick={() => onChangeInternal(x)}>{x.value}</div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
