import React, { FunctionComponent, useState, useEffect } from 'react';

interface IProps {
  message?: string;
}

const FullScreenLoader: FunctionComponent<IProps> = ({ message }) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const handle = window.setTimeout(() => setShowLoader(true), 350);

    return () => window.clearTimeout(handle);
  });

  if (!showLoader) {
    return null;
  }

  return (
    <div className="loader-container">
      <div className="loader">
      </div>
      {message && (
        <p>{message}</p>
      )}
    </div>
  );
};

export default FullScreenLoader;
