export enum PrinterApprovalStatus {
  NotSent = 0,
  AwaitingApproval = 1,
  Approved = 2,
  Rejected = 3,
}

export enum PDFType {
  Unset = 0,
  Download = 1,
  SendToPrinters = 2,
}

export enum PDFSize {
  A4 = 0,
  A3 = 1,
}
