import React, { FunctionComponent, useState, useContext, createContext, useEffect } from 'react';
import { AuthenticationStatus, Site, ClaimType } from '@passport/enums';
import { IClaim } from '@passport/types';
import { CookieStorage } from '@core/storage';
import { PassportService } from '@passport/services';

interface IContextValue {
  isValidating: boolean;
  claims: IClaim[];
  bearerToken: string | null;
  tokenExpiration: string | null;
  authenticationStatus: AuthenticationStatus | null;

  // Loading Indicators
  isLoggingIn: boolean;
  isCheckingUser: boolean;
  isCreatingUser: boolean;
}

interface IPassportContext {
  passportContext: IContextValue;
  setPassportContext: (value: ISetPassportContext) => void;
  logOut: () => void;
  getClaim: (type: ClaimType, claims: IClaim[]) => IClaim | undefined;
  getClaims: (type: ClaimType, claims: IClaim[]) => IClaim[];
}

interface ISetPassportContext {
  isValidating?: boolean;
  claims?: IClaim[];
  bearerToken?: string;
  tokenExpiration?: string;
  authenticationStatus?: AuthenticationStatus;
  isLogginIn?: boolean;
  isCheckingUser?: boolean;
  isCreatingUser?: boolean;
}

const initialState: IPassportContext = {
  passportContext: {
    isValidating: true,
    claims: [],
    bearerToken: CookieStorage.get<string>(process.env.REACT_APP_PASSPORT_BEARER_COOKIE) || null,
    tokenExpiration: null,
    authenticationStatus: null,
    isLoggingIn: false,
    isCheckingUser: false,
    isCreatingUser: false,
  },
  // tslint:disable-next-line: no-empty
  setPassportContext: () => { },
  logOut: () => {
    // Delete the bearer token
    CookieStorage.delete(process.env.REACT_APP_PASSPORT_BEARER_COOKIE);
    // Force a reload to allow for routing to take over.
    window.location.reload();
  },
  getClaim: (type: ClaimType, claims: IClaim[]): IClaim | undefined => PassportService.getClaim(type, claims),
  getClaims: (type: ClaimType, claims: IClaim[]): IClaim[] => PassportService.getClaims(type, claims),
};

const PassportContext = createContext<IPassportContext>(initialState);

export const PassportContextProvider: FunctionComponent = ({ children }) => {
  const [contextValue, _setContextValue] = useState(initialState);

  // Set the "context" value using the private setter
  const setContextValue = (value: ISetPassportContext) => {
    const newValue = { ...contextValue };

    // Set the context value in state
    if (value.authenticationStatus) {
      newValue.passportContext.authenticationStatus = value.authenticationStatus;
    }

    if (value.bearerToken) {
      newValue.passportContext.bearerToken = value.bearerToken;
    }

    if (value.claims) {
      newValue.passportContext.claims = value.claims;
    }

    if (value.tokenExpiration) {
      newValue.passportContext.tokenExpiration = value.tokenExpiration;
    }

    if (value.isValidating !== undefined) {
      newValue.passportContext.isValidating = value.isValidating;
    }

    if (value.isLogginIn !== undefined) {
      newValue.passportContext.isLoggingIn = value.isLogginIn;
    }

    if (value.isCheckingUser !== undefined) {
      newValue.passportContext.isCheckingUser = value.isCheckingUser;
    }

    if (value.isCreatingUser !== undefined) {
      newValue.passportContext.isCreatingUser = value.isCreatingUser;
    }

    if (value.bearerToken !== undefined) {
      // Update the cookie for future references
      CookieStorage.set(process.env.REACT_APP_PASSPORT_BEARER_COOKIE, value.bearerToken);
    }

    // update the context value in state
    _setContextValue(newValue);
  };

  const validateSessionToken = async (token: string, site: Site) => {
    const response = await PassportService.validateSessionToken(token, site);

    // return when we have no value or we have errored
    if (response.data === null) {
      return;
    }

    setContextValue({
      isValidating: false,
      bearerToken: token,
      claims: response.data.claims,
      tokenExpiration: response.data.tokenExpiration,
    });
  };

  // Constructor to check existing token and "refresh" our user details
  useEffect(() => {
    if (contextValue.passportContext.bearerToken === null) {
      return;
    }

    validateSessionToken(contextValue.passportContext.bearerToken, Site.FactTagGenerator);
  }, []);

  return (
    <PassportContext.Provider value={{
      passportContext: contextValue.passportContext,
      logOut: contextValue.logOut,
      setPassportContext: setContextValue,
      getClaim: contextValue.getClaim,
      getClaims: contextValue.getClaims,
    }}
    >
      {children}
    </PassportContext.Provider>
  );
};

export const usePassportContext = () => {
  const value = useContext(PassportContext);

  return value;
};
