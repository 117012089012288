import React, { useState, FunctionComponent } from 'react';
import { PassportService } from '@passport/services';
import { useParams } from 'react-router-dom';
import { AuthenticationRoutes } from '@components/routing';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { IApiError } from '@passport/types';
import { ApiError } from '@passport/components';

interface IProps {
  redirect: (path: string, state?: {}) => void;
}

const ResetPassword: FunctionComponent<IProps> = ({ redirect }) => {
  const [errors, setErrors] = useState<IApiError[]>([]);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { token } = useParams();

  // Handle redirects
  if (token === undefined) {
    redirect(AuthenticationRoutes.login.path);
    return null;
  }

  const resetPassword = async () => {
    const response = await PassportService.resetPassword({
      password,
      confirmPassword,
      resetToken: token,
    });

    // Check errors
    if (response.errors.length > 0) {
      setErrors(response.errors);
    }

    // Check no response
    if (response.data === null || response.data === false) {
      return;
    }

    // Redirect
    redirect(AuthenticationRoutes.login.path);
  };

  return (
    <div className="form__inner">
      <div className="form__fields">
        <h2 className="align--center">Reset Password</h2>
        <p className="align--center">
          Please enter and confirm your new password below
        </p>
        <ApiError fieldName="ResetToken" errors={errors} />
        <div className="form__row">
          <input
            type="password"
            id="txtPassword"
            placeholder="New password…"
            className="form__control"
            value={password}
            onChange={e => setPassword(e.target.value)}
          ></input>
          <label htmlFor="txtEmailAddress" className="form__label">
            Password
        </label>
          <ApiError fieldName="password" errors={errors} />
        </div>
        <div className="form__row">
          <input
            type="password"
            id="txtConfirmPassword"
            placeholder="Confirm new password…"
            className="form__control"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
          ></input>
          <label htmlFor="txtEmailAddress" className="form__label">
            Confirm Password
          </label>
          <ApiError fieldName="confirmPassword" errors={errors} />
        </div>
      </div>
      <button className="btn" type="button" onClick={resetPassword}>
        Reset
    </button>
    </div>
  );
};


const mapDispatchToProps = {
  redirect: (path: string, state?: {}) => push(path, state),
};

export default connect(null, mapDispatchToProps)(ResetPassword);
