import React, { useState } from 'react';

import { Close } from '@components/icons';

const PreviousProjects = () => {
  const [test, setTest] = useState(1);

  return (
    <div className="site-wide">
      <div className="tabs">
        <div className="tabs__container">
          <button className="tab active">Previous Projects</button>
        </div>
        <div className="tabs__create">
          <button className="btn btn--inline">Create New Project</button>
        </div>
      </div>
      <div className="projects">
        <div className="project-item">
          <div className="project-item__container">
            <div className="project-item__content">
              <div className="project-item__title">Project Name Goes Here</div>
              <div className="project-item__date">Last Edited Oct 6, 2019</div>
            </div>
            <div className="project-item__close">
              <Close />
              <div className="project-item__remove">Remove</div>
            </div>
          </div>
        </div>
        <div className="project-item">
          <div className="project-item__container">
            <div className="project-item__content">
              <div className="project-item__title">Project Name Goes Here</div>
              <div className="project-item__date">Last Edited Oct 6, 2019</div>
            </div>
            <div className="project-item__close">
              <Close />
              <div className="project-item__remove">Remove</div>
            </div>
          </div>
        </div>
        <div className="project-item">
          <div className="project-item__container">
            <div className="project-item__content">
              <div className="project-item__title">Project Name Goes Here</div>
              <div className="project-item__date">Last Edited Oct 6, 2019</div>
            </div>
            <div className="project-item__close">
              <Close />
              <div className="project-item__remove">Remove</div>
            </div>
          </div>
        </div>
        <div className="project-item">
          <div className="project-item__container">
            <div className="project-item__content">
              <div className="project-item__title">Project Name Goes Here</div>
              <div className="project-item__date">Last Edited Oct 6, 2019</div>
            </div>
            <div className="project-item__close">
              <Close />
              <div className="project-item__remove">Remove</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviousProjects;
