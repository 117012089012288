import React, { Fragment, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { Import } from '@components/icons';

import LanguageSelector from './components/LanguageSelector';
import { Navigation } from '@components/navigation';
import PreviousProjects from './components/PreviousProjects';
import ProductImport from './components/ProductImport';
import ProductSearch from './components/ProductSearch';
import SelectedProducts from './components/SelectedProducts';

const Styleguide = () => {
  const [activeProducts, setActiveProducts] = useState(false);

  const [activeLanguageSelector, setActiveLanguageSelector] = useState(false);

  const [activeSearch, setActiveSearch] = useState(false);

  const [activeImport, setActiveImport] = useState(false);

  return (
    <Fragment>
      <Navigation>
        <div className="navigation__text" onClick={() => setActiveProducts(!activeProducts)}>
          Cordless
        </div>
        <div className="navigation__text" onClick={() => setActiveProducts(!activeProducts)}>
          Corded
        </div>
        <div className="navigation__text" onClick={() => setActiveImport(!activeImport)}>
          <Import className="navigation__text--icon" />
          Import
        </div>
        <div className="navigation__text" onClick={() => setActiveSearch(!activeSearch)}>
          <MdSearch className="navigation__text--icon" />
          Search
        </div>
      </Navigation>
      <main>
        <div className="site-wide">
          <h1>Main Title</h1>
          <h2>Sub Title</h2>
          <h3>Heading</h3>
          <p>
            Donec rutrum congue leo eget malesuada. Vivamus magna justo, lacinia eget consectetur
            sed, convallis at tellus. Vivamus magna justo, lacinia eget consectetur sed, convallis
            at tellus. Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Cras ultricies
            ligula sed magna dictum porta.
          </p>
          <p>
            Mauris blandit aliquet elit, eget tincidunt nibh pulvinar a. Donec rutrum congue leo
            eget malesuada. Praesent sapien massa, convallis a pellentesque nec, egestas non nisi.
            Vivamus suscipit tortor eget felis porttitor volutpat. Donec rutrum congue leo eget
            malesuada.
          </p>
          <p>
            Donec sollicitudin molestie malesuada. Curabitur arcu erat, accumsan id imperdiet et,
            porttitor at sem. Nulla quis lorem ut libero malesuada feugiat. Vestibulum ante ipsum
            primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec velit neque,
            auctor sit amet aliquam vel, ullamcorper sit amet ligula. Vestibulum ac diam sit amet
            quam vehicula elementum sed sit amet dui.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque velit nisi, pretium ut
            lacinia in, elementum id enim. Cras ultricies ligula sed magna dictum porta. Nulla quis
            lorem ut libero malesuada feugiat. Vivamus suscipit tortor eget felis porttitor
            volutpat.
          </p>
          <button className="btn">Button</button>
          <br />
          <br />
          <button className="btn btn--black">Button</button>
        </div>
        <PreviousProjects />
        <ProductImport active={activeImport} />
        <ProductSearch active={activeSearch} />
        <SelectedProducts setLanguageDropdown={setActiveLanguageSelector} />
        <LanguageSelector active={activeLanguageSelector} />
      </main>
    </Fragment>
  );
};

export default Styleguide;
