import * as React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import '@scss/main.scss';
import { Router } from '@components/routing';
import { store, history } from '@core/redux';
import { PassportContextProvider } from '@passport/hooks';

const App: React.FC = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <PassportContextProvider>
        <Router />
      </PassportContextProvider>
    </ConnectedRouter>
  </Provider>
);

export default App;
