import React, { FunctionComponent, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import { Models, Api } from '@core/types';
import classNames from 'classnames';

interface IProps {
  product: Models.Product;
  quantity?: number;
  isSelected: boolean;
  onChange: (agilityID: number) => void;
  isDark?: boolean;
  onUpdateQuantity?: (params: Api.IUpdateQuantity) => void;
}

const ProductRow: FunctionComponent<IProps> = ({ product, isSelected, onChange, onUpdateQuantity, isDark = false, quantity }) => {
  const [_quantity, _setQuantity] = useState<number>(quantity || 0);
  const fieldID = uniqueId('product_');

  const itemClassNames = classNames({
    'product-select__product-item': true,
    'product-select__product-item--dark': isDark,
  });

  const handleQuantity = (increase: boolean) => {
    if (!quantity || !onUpdateQuantity) {
      return;
    }

    let newQuantity = _quantity;

    if (increase) {
      newQuantity += 1;
    } else {
      newQuantity -= 1;
    }

    if (newQuantity < 0) {
      newQuantity = 0;
    }

    onUpdateQuantity({
      agilityID: product.variantAgilityID,
      quantity: newQuantity,
    });

    // update our local UI state
    _setQuantity(newQuantity);
  };

  return (
    <div key={product.variantAgilityID} className={itemClassNames}>
      <input id={fieldID} type="checkbox" checked={isSelected} onChange={() => onChange(product.variantAgilityID)} className="product-select__product-item--input" />
      <label htmlFor={fieldID} className="product-select__product-item--label">
        {product.variantName}


        {quantity && (
          <div className="product-select__product-item--controls">
            <button onClick={() => handleQuantity(false)}>-</button>
            {_quantity}
            <button onClick={() => handleQuantity(true)}>+</button>
          </div>
        )}
      </label>
    </div>
  );
};

export default ProductRow;
