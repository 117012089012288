import { TranslationActionTypes, FETCH_TRANSLATIONS, FETCH_TRANSLATIONS_SUCCESS, FETCH_TRANSLATIONS_ERROR } from './actions';
import { States, Models } from '@core/types';

const initialState: States.ITranslation = {
  isLoading: false,
  values: [],
};

function translationReducer(state: States.ITranslation = initialState, action: TranslationActionTypes): States.ITranslation {
  switch (action.type) {
    case FETCH_TRANSLATIONS: {
      const newState = { ...state };

      newState.isLoading = true;

      return newState;
    }

    case FETCH_TRANSLATIONS_SUCCESS: {
      const newState = { ...state };

      const values: Models.Translation[] = [];

      Object.keys(action.payload).forEach(key => {
        values.push({
          key,
          value: action.payload[key],
        });
      });

      newState.values = values;
      newState.isLoading = false;

      return newState;
    }

    case FETCH_TRANSLATIONS_ERROR: {
      const newState = { ...state };

      newState.isLoading = true;

      return newState;
    }

    default:
      return state;
  }
}

export default translationReducer;
