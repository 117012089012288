import { Api, Models } from '@core/types';
import { IClearProject } from '../shared';

export const FETCH_NAVIGATION = 'PRODUCTS/FETCH_NAVIGATION';
export const FETCH_NAVIGATION_SUCCESS = 'PRODUCTS/FETCH_NAVIGATION_SUCCESS';
export const FETCH_NAVIGATION_ERROR = 'PRODUCTS/FETCH_NAVIGATION_ERROR';

export const FETCH_PROJECT_PRODUCTS = 'PRODUCTS/FETCH_PROJECT_PRODUCTS';
export const FETCH_PROJECT_PRODUCTS_SUCCESS = 'PRODUCTS/FETCH_PROJECT_PRODUCTS_SUCCESS';
export const FETCH_PROJECT_PRODUCTS_ERROR = 'PRODUCTS/FETCH_PROJECT_PRODUCTS_ERROR';

export const SEARCH_PRODUCTS = 'PRODUCTS/SEARCH';
export const SEARCH_PRODUCTS_SUCCESS = 'PRODUCTS/SEARCH_PRODUCTS_SUCCESS';
export const SEARCH_PRODUCTS_ERROR = 'PRODUCTS/SEARCH_PRODUCTS_ERROR';

export const IMPORT_BY_ARTICLE_NUMBER = 'PRODUCTS/IMPORT_BY_ARTICLE_NUMBER';
export const IMPORT_BY_ARTICLE_NUMBER_SUCCESS = 'PRODUCTS/IMPORT_BY_ARTICLE_NUMBER_SUCCESS';
export const IMPORT_BY_ARTICLE_NUMBER_ERROR = 'PRODUCTS/IMPORT_BY_ARTICLE_NUMBER_ERROR';

export interface IFetchNavigation {
  type: typeof FETCH_NAVIGATION;
}

export interface IFetchNavigationSuccess {
  type: typeof FETCH_NAVIGATION_SUCCESS;
  payload: Api.IFetchNavigationResponse;
}

export interface IFetchNavigationError {
  type: typeof FETCH_NAVIGATION_ERROR;
  payload: Api.IApiError[];
}

export interface IFetchProjectProducts {
  type: typeof FETCH_PROJECT_PRODUCTS;
}

export interface IFetchProjectProductsSuccess {
  type: typeof FETCH_PROJECT_PRODUCTS_SUCCESS;
  payload: Api.IFetchProductsResponse;
}

export interface IFetchProjectProductsError {
  type: typeof FETCH_PROJECT_PRODUCTS_ERROR;
  payload: Api.IApiError[];
}

export interface ISearchProducts {
  type: typeof SEARCH_PRODUCTS;
}

export interface ISearchProductsSuccess {
  type: typeof SEARCH_PRODUCTS_SUCCESS;
  payload: Api.IFetchProductsResponse;
}

export interface ISearchProductsError {
  type: typeof SEARCH_PRODUCTS_ERROR;
  payload: Api.IApiError[];
}

export interface IImportByArticleNumber {
  type: typeof IMPORT_BY_ARTICLE_NUMBER;
}

export interface IImportByArticleNumberSuccess {
  type: typeof IMPORT_BY_ARTICLE_NUMBER_SUCCESS;
  payload: {
    importResults: Models.Product[];
    importErrors: string[];
  };
}

export interface IImportByArticleNumberError {
  type: typeof IMPORT_BY_ARTICLE_NUMBER_ERROR;
  payload: Api.IApiError[];
}

export type ProductActionTypes =
  | IFetchNavigation
  | IFetchNavigationSuccess
  | IFetchNavigationError
  | IFetchProjectProducts
  | IFetchProjectProductsError
  | IFetchProjectProductsSuccess
  | IClearProject
  | ISearchProducts
  | ISearchProductsError
  | ISearchProductsSuccess
  | IImportByArticleNumber
  | IImportByArticleNumberSuccess
  | IImportByArticleNumberError;
