import { FETCH_TRANSLATIONS, FETCH_TRANSLATIONS_SUCCESS, FETCH_TRANSLATIONS_ERROR } from './actions';
import { ApiService } from '@core/services';
import { Api } from '@core/types';
import { Dispatch } from 'redux';
import { keys } from '@core/translations';
import { sessionKeys } from '@core/storage';

export const fetchTranslations = (cultureCode: string) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_TRANSLATIONS,
  });

  const response = await ApiService.rawRequest<Api.IFetchTranslationsResponse>({
    baseUrl: 'https://www.milwaukeetool.eu',
    method: Api.Method.POST,
    controller: 'localisation',
    slug: 'localisations',
    params: {
      cultureCode,
      keys,
    },
    cacheKey: `${sessionKeys.translations}|${cultureCode}`,
  });

  dispatch({
    type: FETCH_TRANSLATIONS_SUCCESS,
    payload: response.Localisations,
  });

  // dispatch({
  //   type: FETCH_TRANSLATIONS_ERROR,
  //   payload: e,
  // });
};
