import querystring from 'querystring';
import { IRequest, IBaseResponse } from '@passport/types';
import { ApiMethod } from '@passport/enums';

class ApiService {
  async request<T>(request: IRequest): Promise<IBaseResponse<T>> {
    // Get the url
    const url = this.getUrl(request);

    // Headers
    const headers = new Headers({
      'Content-Type': 'application/json; charset=utf-8',
      Accept: 'application/json',
    });

    // construct a request
    const requestOptions: RequestInit = {
      method: request.method,
      headers,
      mode: 'cors',
      cache: 'default',
      body: this.getBody(request),
    };

    // await the response
    const response = await fetch(url, requestOptions);

    const baseResponse = (await response.json()) as IBaseResponse<T>;

    // Check response code and response success
    // if (response.status === 403) {
    //   const message = `${response.status}: ${response.statusText}`;
    //   throw Error(message);
    // }

    return baseResponse;
  }

  getUrl(request: IRequest) {
    let url = `${process.env.REACT_APP_PASSPORT_ENDPOINT}/api/${request.controller}/${request.slug}`;

    if ((request.method === ApiMethod.GET || request.method === ApiMethod.DELETE) && request.params) {
      url += '?';
      url += querystring.stringify({ ...request.params });
    }

    return url;
  }

  getBody(request: IRequest) {
    if (request.method === ApiMethod.POST || request.method === ApiMethod.DELETE) {
      return JSON.stringify(request.params);
    }

    return null;
  }
}

export default new ApiService();
