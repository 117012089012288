import { IStorage } from './types';

export const localKeys = {};

class LocalStorage implements IStorage {
  /**
   * Gets a value using the given key from local storage
   * @param {string} key
   * @returns undefined if not found
   */
  get<T>(key: string): T | undefined {
    const value = localStorage.getItem(key);

    if (value === null || value === undefined) {
      return undefined;
    }

    return JSON.parse(value) as T;
  }

  /**
   * Sets a value in local storage.
   * @param {string} key
   * @param {*} value
   */
  set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Removes an item from local storage
   * @param key key
   */
  delete(key: string) {
    localStorage.removeItem(key);
  }
}

export default new LocalStorage();
