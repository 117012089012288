import ApiService from './ApiService';
import {
  IClaim,
  IAuthenticateRequest,
  IAuthenticateResponse,
  ICheckUserExistsRequest,
  ICreateUserRequest,
  ICreateUserResponse,
  IValidateSessionTokenResponse,
  IForgottenPasswordRequest,
  IResetPasswordRequest,
  IBaseResponse,
  IResendVerificationEmailRequest,
} from '@passport/types';
import { ClaimType, Site, ApiMethod } from '@passport/enums';

class PassportService {
  /**
   * Checks if the given user exists
   * @param {ICheckUserExistsRequest} request The request
   */
  async checkUserExists(request: ICheckUserExistsRequest): Promise<IBaseResponse<string | null>> {
    const response = await ApiService.request<string | null>({
      controller: 'user',
      method: ApiMethod.GET,
      slug: 'check-user-exists',
      params: request,
    });

    return response;
  }

  /**
   * Creates a user using the given request
   * @param request The request
   */
  async createUser(request: ICreateUserRequest): Promise<IBaseResponse<ICreateUserResponse | null>> {
    const response = await ApiService.request<ICreateUserResponse>({
      controller: 'user',
      method: ApiMethod.POST,
      slug: 'create-user',
      params: request,
    });

    return response;
  }

  /**
   * Verifies the given token
   * @param {string} token The token to verify
   */
  async verifyAccount(token: string): Promise<IBaseResponse<boolean | null>> {
    const response = await ApiService.request<boolean | null>({
      controller: 'user',
      method: ApiMethod.POST,
      slug: 'verify-account',
      params: {
        verificationToken: token,
      },
    });

    return response;
  }

  /**
   * Authenticates the user using the given request
   * @param {IAuthenticateRequest} request The request
   */
  async authenticate(request: IAuthenticateRequest): Promise<IBaseResponse<IAuthenticateResponse | null>> {
    const response = await ApiService.request<IAuthenticateResponse>({
      controller: 'authentication',
      method: ApiMethod.POST,
      slug: 'authenticate',
      params: request,
    });

    return response;
  }

  /**
   * Validates the given session token
   * @param {string} token The session token
   */
  async validateSessionToken(token: string, siteID: Site): Promise<IBaseResponse<IValidateSessionTokenResponse | null>> {
    const response = await ApiService.request<IValidateSessionTokenResponse>({
      controller: 'authentication',
      method: ApiMethod.GET,
      slug: 'validate-session-token',
      params: {
        token,
        siteID,
      },
    });

    return response;
  }

  /**
   * Triggers a reset password email to be sent to the given email address for the site
   * @param {IForgottenPasswordRequest} request The request
   */
  async forgottenPassword(request: IForgottenPasswordRequest): Promise<IBaseResponse<boolean | null>> {
    const response = await ApiService.request<boolean | null>({
      controller: 'user',
      method: ApiMethod.POST,
      slug: 'forgotten-password',
      params: {
        emailAddress: request.emailAddress,
        siteID: request.siteID,
      },
    });

    return response;
  }

  /**
   * Resets the users password using the given token and password
   * @param {IResetPasswordRequest} request The request
   */
  async resetPassword(request: IResetPasswordRequest): Promise<IBaseResponse<boolean | null>> {
    const response = await ApiService.request<boolean | null>({
      controller: 'user',
      method: ApiMethod.POST,
      slug: 'reset-password',
      params: {
        password: request.password,
        confirmPassword: request.confirmPassword,
        resetToken: request.resetToken,
      },
    });

    return response;
  }

  /**
   * Resends the users verification email
   * @param {IResendVerificationEmailRequest} request The request
   */
  async resendVerificationEmail(request: IResendVerificationEmailRequest): Promise<IBaseResponse<boolean>> {
    const response = await ApiService.request<boolean>({
      controller: 'user',
      method: ApiMethod.POST,
      slug: 'resend-verification-email',
      params: {
        emailAddress: request.emailAddress,
        siteID: request.siteID,
      },
    });

    return response;
  }

  /**
   * Returns a list of claims that match the given type
   * @param {ClaimType} type The claim type
   * @param {IClaim[]} claims The claims
   */
  getClaims(type: ClaimType, claims: IClaim[]): IClaim[] {
    return claims.filter(x => x.type.toLowerCase().indexOf(type.toLowerCase()) > -1);
  }

  // /**
  //  * Matches the first instance of the claim using the given name
  //  * @param {ClaimType} type The claim type
  //  * @param {IClaim[]} claims The claims
  //  */
  // getClaim(claims: IClaim[], type: ClaimType): IClaim | undefined {
  //   return claims.find(x => x.type.toLowerCase() === type.toLowerCase());
  // }

  /**
   * Matches the first instance of the claim using the given name and value
   * @param claims
   * @param type
   * @param value
   */
  getClaim(type: ClaimType, claims: IClaim[], value?: string): IClaim | undefined {
    return claims.find(x => x.type.toLowerCase() === type.toLowerCase());
  }
}

export default new PassportService();
