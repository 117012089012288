import React, { FunctionComponent } from 'react';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';

interface IProps {
  visible: boolean;
  showClose?: boolean;
  onClose?: () => void;
}

const Modal: FunctionComponent<IProps> = ({ children, onClose, visible, showClose = true, }) => {
  const formClassNames = classNames({
    'form-modal': true,
    'is-active': visible,
  });

  return (
    <div className={formClassNames}>
      <div className="form__overlay"></div>
      <div className="form">
        {showClose && (
          <div className="form__close" onClick={() => onClose && onClose()}>
            <MdClose />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default Modal;
