import React, { Fragment, FunctionComponent } from 'react';
import { Navigation, ProductSelect, ProductImport, ProductSearch } from '@components/navigation';
import { ProjectDetail } from '@components/projects';
import { AppEnums } from '@core/enums';
import { States, Api, Models } from '@core/types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import useConstructor from '@hooks/useConstructor';
import { projectActionCreators } from '@redux/projects';
import { usePassportContext } from '@passport/hooks';
import { Logo } from '@components/icons';

interface IProps {
  project?: States.IProjectState;
  product?: States.IProductState;
  fetchProject: (params: Api.IFetchProjectRequest) => void;
}

const Project: FunctionComponent<IProps> = ({ project, product, fetchProject }) => {
  const { passportContext } = usePassportContext();
  const { projectGUID } = useParams();

  useConstructor(() => fetchProject({
    bearerToken: passportContext.bearerToken || '',
    projectGUID: projectGUID || ''
  }));

  const renderCategory = (category: Models.Category | null, navigationID: AppEnums.Navigation) => {
    if (category === null) {
      return null;
    }

    return <ProductSelect category={category} navigationID={navigationID} />;
  };

  return (
    <Fragment>
      <Navigation />
      <main>
        <Logo className="app__bg" />
        {product && projectGUID && project && project.currentProject && (
          <Fragment>
            <div className="site-wide">
              <ProjectDetail />
            </div>

            {renderCategory(product.cordless, AppEnums.Navigation.Cordless)}
            {renderCategory(product.corded, AppEnums.Navigation.Corded)}
            {renderCategory(product.m18, AppEnums.Navigation.M18)}
            {renderCategory(product.m12, AppEnums.Navigation.M12)}

            <ProductImport />
            <ProductSearch />
          </Fragment>
        )}
      </main>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
  product: state.product,
});

const mapDispatchToProps = {
  fetchProject: (params: Api.IFetchProjectRequest) => projectActionCreators.fetchProject(params),
};

export default connect(mapStateToProps, mapDispatchToProps)(Project);
