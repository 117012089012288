import { Api, Models } from '@core/types';
import { IClearProject } from '../shared';

export const UPDATE_PROJECT = 'PROJECTS/UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'PROJECTS/UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'PROJECTS/UPDATE_PROJECT_ERROR';

export const UPDATE_QUANTITY = 'PROJECTS/UPDATE_QUANTITY';

export const FETCH_PROJECT = 'PROJECTS/FETCH_PROJECT';
export const FETCH_PROJECT_SUCCESS = 'FETCH_PROJECT_SUCCESS/FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECT_ERROR = 'PROJECTS/FETCH_PROJECT_ERROR';

export const FETCH_PROJECTS = 'PROJECTS/FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'PROJECTS/FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_ERROR = 'PROJECTS/FETCH_PROJECTS_ERROR';

export const CREATE_PROJECT = 'PROJECTS/CREATE_PROJECT';
export const CREATE_PROJECT_SUCCESS = 'PROJECTS/CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_ERROR = 'PROJECTS/CREATE_PROJECT_ERROR';

export const DELETE_PROJECT = 'PROJECTS/DELETE_PROJECT';
export const DELETE_PROJECT_SUCCESS = 'PROJECTS/DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'PROJECTS/DELETE_PROJECT_ERROR';

export const GENERATE_PDF = 'PROJECTS/GENERATE_PDF';
export const GENERATE_PDF_SUCCESS = 'PROJECTS/GENERATE_PDF_SUCCESS';
export const GENERATE_PDF_ERROR = 'PROJECTS/GENERATE_PDF_ERROR';

export const SEND_FOR_APPROVAL = 'PROJECTS/SEND_FOR_APPROVAL';
export const SEND_FOR_APPROVAL_SUCCESS = 'PROJECTS/SEND_FOR_APPROVAL_SUCCESS';
export const SEND_FOR_APPROVAL_ERROR = 'PROJECTS/SEND_FOR_APPROVAL_ERROR';

export const SET_PDF_MODAL = 'PROJECTS/SET_PDF_MODAL';
export const CLEAR_PDF = 'PROJECTS/CLEAR_PDF';
export const DOWNLOAD_PDF = 'PROJECTS/DOWNLOAD_PDF';

export interface IFetchProjects {
  type: typeof FETCH_PROJECTS;
}

export interface IFetchProjectsSuccess {
  type: typeof FETCH_PROJECTS_SUCCESS;
  payload: Api.IFetchProjectsResponse;
}

export interface IFetchProjectsError {
  type: typeof FETCH_PROJECTS_ERROR;
  payload: Api.IApiError[];
}

export interface IFetchProject {
  type: typeof FETCH_PROJECT;
}

export interface IFetchProjectSuccess {
  type: typeof FETCH_PROJECT_SUCCESS;
  payload: Api.IFetchProjectResponse;
}

export interface IFetchProjectError {
  type: typeof FETCH_PROJECT_ERROR;
  payload: Api.IApiError[];
}

export interface ICreateProject {
  type: typeof CREATE_PROJECT;
}

export interface ICreateProjectSuccess {
  type: typeof CREATE_PROJECT_SUCCESS;
  payload: Api.ICreateProjectRequest;
}

export interface ICreateProjectError {
  type: typeof CREATE_PROJECT_ERROR;
  payload: Api.IApiError[];
}

export interface IDeleteProject {
  type: typeof DELETE_PROJECT;
}

export interface IDeleteProjectSuccess {
  type: typeof DELETE_PROJECT_SUCCESS;
}

export interface IDeleteProjectError {
  type: typeof DELETE_PROJECT_ERROR;
  payload: Api.IApiError[];
}

export interface IAddProducts {
  type: typeof UPDATE_PROJECT;
}

export interface IAddProductsSuccess {
  type: typeof UPDATE_PROJECT_SUCCESS;
  payload: Api.IUpdateProjectRequest;
}

export interface IAddProductsError {
  type: typeof UPDATE_PROJECT_ERROR;
  payload: Api.IApiError[];
}

export interface IGeneratePDF {
  type: typeof GENERATE_PDF;
}

export interface IGeneratePDFSuccess {
  type: typeof GENERATE_PDF_SUCCESS;
  payload: Api.IGeneratePDFResponse;
}

export interface IGeneratePDFError {
  type: typeof GENERATE_PDF_ERROR;
  payload: Api.IApiError[];
}

export interface IUpdateQuantity {
  type: typeof UPDATE_QUANTITY;
  payload: Api.IUpdateQuantity;
}

export interface ISendForApproval {
  type: typeof SEND_FOR_APPROVAL;
}

export interface ISendForApprovalSuccess {
  type: typeof SEND_FOR_APPROVAL_SUCCESS;
  payload: boolean;
}

export interface ISendForApprovalError {
  type: typeof SEND_FOR_APPROVAL_ERROR;
  payload: Api.IApiError[];
}

export interface ISetPDFModal {
  type: typeof SET_PDF_MODAL;
  payload: Models.SetPDFModal;
}

export interface IClearPDF {
  type: typeof CLEAR_PDF;
}

export interface IDownloadPDF {
  type: typeof DOWNLOAD_PDF;
}

export type ProjectActionTypes =
  | IFetchProject
  | IFetchProjectSuccess
  | IFetchProjectError
  | IFetchProjects
  | IFetchProjectsSuccess
  | IFetchProjectsError
  | ICreateProject
  | ICreateProjectError
  | ICreateProjectSuccess
  | IDeleteProject
  | IDeleteProjectSuccess
  | IDeleteProjectError
  | IAddProducts
  | IAddProductsSuccess
  | IAddProductsError
  | IGeneratePDF
  | IGeneratePDFSuccess
  | IGeneratePDFError
  | IClearProject
  | IUpdateQuantity
  | ISendForApproval
  | ISendForApprovalError
  | ISendForApprovalSuccess
  | ISetPDFModal
  | IClearPDF
  | IDownloadPDF;
