import React, { FunctionComponent } from 'react';
import { Models, Api } from '@core/types';
import { Close } from '@components/icons';
import { connect } from 'react-redux';
import { projectActionCreators } from '@redux/projects';
import { usePassportContext } from '@passport/hooks';
import { Routes } from '@components/routing';
import { push } from 'connected-react-router';

interface IProps {
  project: Models.Project;
  deleteProject: (params: Api.IDeleteProjectRequest) => void;
  redirect: (url: string) => void;
}

const ProjectRow: FunctionComponent<IProps> = ({ project, deleteProject, redirect }) => {
  const { passportContext } = usePassportContext();

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();

    deleteProject({
      bearerToken: passportContext.bearerToken || '',
      projectGUID: project.projectGUID,
    });
  };

  const handleClick = () => {
    const url = `${Routes.project.slug}${project.projectGUID}`;

    redirect(url);
  };

  return (
    <div className="project-item project-item--link" onClick={handleClick}>
      <div className="project-item__container">
        <div className="project-item__content">
          <div className="project-item__title">{project.projectName}</div>
          <div className="project-item__date">Last Edited {project.lastModifiedISO}</div>
        </div>
        <div className="project-item__controls">
          <div className="project-item__button" onClick={handleDelete}>
            <Close />
            <div className="project-item__remove">Remove</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  deleteProject: (params: Api.IDeleteProjectRequest) => projectActionCreators.deleteProject(params),
  redirect: (url: string) => push(url),
};

export default connect(null, mapDispatchToProps)(ProjectRow);
