import React, { FunctionComponent, Fragment } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { States, Api } from '@core/types';
import ApprovalRow from './ApprovalRow';
import { FullScreenLoader } from '@components/loaders';
import useConstructor from '@hooks/useConstructor';
import { adminActionCreators } from '@redux/admin';
import { ClaimType, RoleType } from '@passport/enums';
import { usePassportContext } from '@passport/hooks';

interface IProps {
  admin?: States.IAdminState;
  fetchPendingApprovals: (params: Api.IFetchPendingApprovalsRequest) => void;
}

const PendingApprovals: FunctionComponent<IProps> = ({ admin, fetchPendingApprovals }) => {
  const { passportContext, getClaims } = usePassportContext();
  const approvalClaims = getClaims(ClaimType.Role, passportContext.claims).filter(x => x.value.startsWith(RoleType.SentToPrintApprover));

  // The app should have the approvals at this point
  // But it's good to refetch them regardless
  useConstructor(() => {
    if (!passportContext.bearerToken || approvalClaims.length === 0) {
      return;
    }

    const cultureCodes = approvalClaims.map(x => {
      // example: role.ftg.sent-to-print-approver.en-gb
      // Get the last index of '.'
      const index = x.value.lastIndexOf('.');

      // + 1 to skip the last '.' and return the culture code
      return x.value.substring(index + 1, x.value.length);
    });

    fetchPendingApprovals({
      bearerToken: passportContext.bearerToken,
      cultureCodes,
    });
  });

  if (!admin) {
    return null;
  }

  if (admin.isLoadingPendingPDFs) {
    return (
      <div className="sitewide">
        <FullScreenLoader message="Loading pending approvals" />
      </div>
    );
  }

  return (
    <Fragment>
      <div className="tabs">
        <div className="tabs__container">
          <button className="tab active">Pending Approvals</button>
        </div>
        <div className="tabs__create">
        </div>
      </div>
      <div className="projects">
        <TransitionGroup>
          {admin.pendingPDFs.map(x => (
            <CSSTransition key={x.projectPDFID} classNames="fade" timeout={300}>
              <ApprovalRow key={x.projectPDFID} project={x} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  admin: state.admin,
});

const mapDispatchToProps = {
  fetchPendingApprovals: (params: Api.IFetchPendingApprovalsRequest) => adminActionCreators.fetchPendingApprovals(params),
};

export default connect(mapStateToProps, mapDispatchToProps)(PendingApprovals);
