import { States } from '@core/types';
import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_ERROR,
  ProjectActionTypes,
  CREATE_PROJECT,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_ERROR,
  DELETE_PROJECT_ERROR,
  DELETE_PROJECT,
  DELETE_PROJECT_SUCCESS,
  FETCH_PROJECT_ERROR,
  FETCH_PROJECT,
  FETCH_PROJECT_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  GENERATE_PDF_ERROR,
  GENERATE_PDF,
  GENERATE_PDF_SUCCESS,
  SEND_FOR_APPROVAL,
  SEND_FOR_APPROVAL_SUCCESS,
  SET_PDF_MODAL,
  CLEAR_PDF,
} from './actions';
import { CLEAR_PROJECT } from '../shared';
import { ProjectEnums } from '@core/enums';

const initialState: States.IProjectState = {
  isCreating: false,
  isLoading: false,
  isLoadingProject: false,
  isDeleting: false,
  isAddingProducts: false,
  projects: [],
  currentProject: null,
  projectPDF: null,
  errors: [],
  pdfModal: {
    isOpen: false,
    pdfType: ProjectEnums.PDFType.Unset,
    isGenerating: false,
    isSendingForApproval: false,
  },
};

function projectReducer(state: States.IProjectState = initialState, action: ProjectActionTypes): States.IProjectState {
  switch (action.type) {
    case FETCH_PROJECTS: {
      const newState = { ...state };

      newState.isLoading = true;

      return newState;
    }

    case FETCH_PROJECTS_SUCCESS: {
      const newState = { ...state };

      newState.isLoading = false;
      newState.projects = action.payload.projects;

      return newState;
    }

    case FETCH_PROJECT: {
      const newState = { ...state };

      newState.isLoadingProject = true;

      return newState;
    }

    case FETCH_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.currentProject = action.payload.project;
      newState.isLoadingProject = false;

      return newState;
    }

    case GENERATE_PDF_ERROR:
    case UPDATE_PROJECT_ERROR:
    case FETCH_PROJECT_ERROR:
    case DELETE_PROJECT_ERROR:
    case CREATE_PROJECT_ERROR:
    case FETCH_PROJECTS_ERROR: {
      const newState = { ...state };

      newState.isLoading = false;
      newState.isLoadingProject = false;
      newState.isDeleting = false;
      newState.errors = action.payload;

      return newState;
    }

    case CREATE_PROJECT: {
      const newState = { ...state };

      newState.isCreating = true;

      return newState;
    }

    case CREATE_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.isCreating = false;

      return newState;
    }

    case DELETE_PROJECT: {
      const newState = { ...state };

      newState.isDeleting = true;

      return newState;
    }

    case CLEAR_PROJECT: {
      const newState = { ...state };

      newState.currentProject = null;

      return newState;
    }

    case DELETE_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.isDeleting = false;

      return newState;
    }

    case UPDATE_PROJECT: {
      const newState = { ...state };

      newState.isAddingProducts = true;

      return newState;
    }

    case UPDATE_PROJECT_SUCCESS: {
      const newState = { ...state };

      newState.isAddingProducts = false;

      if (newState.currentProject !== null) {
        newState.currentProject.products = action.payload.projectProducts;
      }

      return newState;
    }

    case GENERATE_PDF: {
      const newState = { ...state };

      newState.pdfModal.isGenerating = true;

      return newState;
    }

    case GENERATE_PDF_SUCCESS: {
      const newState = { ...state };

      newState.pdfModal.isGenerating = false;
      newState.projectPDF = action.payload.projectPDF;

      return newState;
    }

    case SEND_FOR_APPROVAL: {
      const newState = { ...state };

      newState.pdfModal.isSendingForApproval = true;

      return newState;
    }

    case SEND_FOR_APPROVAL_SUCCESS: {
      const newState = { ...state };

      newState.pdfModal.isSendingForApproval = false;

      return newState;
    }

    case SET_PDF_MODAL: {
      const newState = { ...state };

      if (action.payload.isGenerating !== undefined) {
        newState.pdfModal.isGenerating = action.payload.isGenerating;
      }

      if (action.payload.isOpen !== undefined) {
        newState.pdfModal.isOpen = action.payload.isOpen;
      }

      if (action.payload.isSendingForApproval !== undefined) {
        newState.pdfModal.isSendingForApproval = action.payload.isSendingForApproval;
      }

      if (action.payload.pdfType !== undefined) {
        newState.pdfModal.pdfType = action.payload.pdfType;
      }

      return newState;
    }

    case CLEAR_PDF: {
      const newState = { ...state };

      newState.projectPDF = null;

      return newState;
    }

    default:
      return state;
  }
}

export default projectReducer;
