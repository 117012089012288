import React from 'react';

const IconConfirmation = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path
      d="M7.981,15.023,17,6.009,15.587,4.554,7.981,12.16,4.413,8.592,3,10ZM10,0a9.631,9.631,0,0,1,7.066,2.934A9.631,9.631,0,0,1,20,10a9.631,9.631,0,0,1-2.934,7.066A9.631,9.631,0,0,1,10,20a9.631,9.631,0,0,1-7.066-2.934A9.631,9.631,0,0,1,0,10,9.631,9.631,0,0,1,2.934,2.934,9.631,9.631,0,0,1,10,0Z"
      fill="#db011c"
    />
  </svg>
);

export default IconConfirmation;
