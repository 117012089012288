import React, { useState } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { PassportService } from '@passport/services';
import { AuthenticationRoutes } from '@components/routing';

const VerifyAccount = () => {
  const [redirect, setRedirect] = useState(false);
  const { token } = useParams();

  // Handle redirects
  if (token === undefined || redirect) {
    return <Redirect to={AuthenticationRoutes.login.path} />;
  }

  const verifyAccount = async () => {
    await PassportService.verifyAccount(token);
    setRedirect(true);
  };

  verifyAccount();
  return null;
};

export default VerifyAccount;
