import { States } from '@core/types';
import {
  ProductActionTypes,
  FETCH_NAVIGATION,
  FETCH_NAVIGATION_SUCCESS,
  FETCH_NAVIGATION_ERROR,
  FETCH_PROJECT_PRODUCTS,
  FETCH_PROJECT_PRODUCTS_ERROR,
  FETCH_PROJECT_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ERROR,
  SEARCH_PRODUCTS_SUCCESS,
  IMPORT_BY_ARTICLE_NUMBER,
  IMPORT_BY_ARTICLE_NUMBER_SUCCESS,
  IMPORT_BY_ARTICLE_NUMBER_ERROR,
} from './actions';
import { CLEAR_PROJECT } from '../shared';
import { ProductService } from '@core/services';

const initialState: States.IProductState = {
  isLoadingNavigation: false,
  errors: [],
  cordless: null,
  corded: null,
  m18: null,
  m12: null,

  isLoadingCurrentCategories: false,
  currentCategories: [],

  isLoadingSearchResults: false,
  searchResults: [],

  isLoadingImportResults: false,
  importResults: [],
  importErrors: [],
};

function productReducer(state: States.IProductState = initialState, action: ProductActionTypes): States.IProductState {
  switch (action.type) {
    case FETCH_NAVIGATION: {
      const newState = { ...state };

      newState.isLoadingNavigation = true;

      return newState;
    }

    case FETCH_NAVIGATION_SUCCESS: {
      const newState = { ...state };

      newState.isLoadingNavigation = false;

      newState.cordless = action.payload.cordless;
      newState.corded = action.payload.corded;
      newState.m18 = action.payload.m18;
      newState.m12 = action.payload.m12;

      return newState;
    }

    case FETCH_PROJECT_PRODUCTS: {
      const newState = { ...state };

      newState.isLoadingCurrentCategories = true;

      return newState;
    }

    case IMPORT_BY_ARTICLE_NUMBER_ERROR:
    case SEARCH_PRODUCTS_ERROR:
    case FETCH_NAVIGATION_ERROR:
    case FETCH_PROJECT_PRODUCTS_ERROR: {
      const newState = { ...state };

      newState.errors = action.payload;

      return newState;
    }

    case FETCH_PROJECT_PRODUCTS_SUCCESS: {
      const newState = { ...state };

      newState.isLoadingCurrentCategories = false;
      newState.currentCategories = action.payload.categories;
      newState.importResults = [];
      newState.importErrors = [];

      return newState;
    }

    case CLEAR_PROJECT: {
      const newState = { ...state };

      newState.currentCategories = [];

      return newState;
    }

    case SEARCH_PRODUCTS: {
      const newState = { ...state };

      newState.isLoadingSearchResults = true;

      return newState;
    }

    case SEARCH_PRODUCTS_SUCCESS: {
      const newState = { ...state };

      newState.searchResults = ProductService.flatten(action.payload.categories);

      return newState;
    }

    case IMPORT_BY_ARTICLE_NUMBER: {
      const newState = { ...state };

      newState.isLoadingImportResults = true;

      return newState;
    }

    case IMPORT_BY_ARTICLE_NUMBER_SUCCESS: {
      const newState = { ...state };

      // Update the state
      newState.importResults = action.payload.importResults;

      // update the import errors
      newState.importErrors = action.payload.importErrors;

      return newState;
    }

    default:
      return state;
  }
}

export default productReducer;
