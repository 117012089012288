import React, { FunctionComponent, Fragment, useState } from 'react';
import { Models, States, Api } from '@core/types';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { ProductRow } from '@components/products';
import { usePassportContext } from '@passport/hooks';
import { ClaimType } from '@passport/enums';

interface IProps {
  project: States.IProjectState;
  categories: Models.Category[];
  selectedIds: number[];
  onChange: Function;
  updateProject: (params: Api.IUpdateProjectRequest) => void;
}

const ProjectDetailTable: FunctionComponent<IProps> = ({ project, categories, selectedIds, updateProject, onChange }) => {
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(ClaimType.Locality, passportContext.claims);
  const [accordions, setAccordions] = useState<string[]>([]);

  const isActive = (id: string) => {
    return accordions.indexOf(id) === -1 ? 'is-active' : '';
  };

  const toggleAccordion = (id: string) => {
    let newState = [];
    const existingIndex = accordions.indexOf(id);

    if (existingIndex > -1) {
      newState = accordions.filter(x => x !== id);
    } else {
      newState = [
        ...accordions,
        id,
      ];
    }

    setAccordions(newState);
  };

  const getQuantity = (agilityId: number) => {
    return project.currentProject?.products.find(x => x.productID === agilityId)?.quantity || 0;
  };

  const handleUpdateQuantity = (params: Api.IUpdateQuantity) => {
    if (!project || !project.currentProject || !cultureClaim || !passportContext.bearerToken) {
      return;
    }

    let refetchProject = false;

    // Copy the products
    const newProducts = [...project.currentProject.products];

    // Find the index of the existing product to update
    const existingIndex = newProducts.findIndex(x => x.productID === params.agilityID);

    if (existingIndex === -1) {
      return;
    }

    if (params.quantity <= 0) {
      newProducts.splice(existingIndex, 1);

      // We technically only need to refetch the products when one gets deleted
      refetchProject = true;
    } else {
      newProducts[existingIndex].quantity = params.quantity;
    }

    updateProject({
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      projectGUID: project.currentProject.projectGUID,
      projectProducts: newProducts,
      refetchProject,
    });
  };

  return (
    <Fragment>
      {categories.map(category => (
        <div key={category.categoryName} className="selected-products__container">
          <div className={`selected-products__category ${isActive(category.categoryName)}`} onClick={() => toggleAccordion(category.categoryName)}>
            {category.categoryName}
            <MdKeyboardArrowDown className="selected-products__category--arrow" />
          </div>
          <div className={`selected-products__category-container ${isActive(category.categoryName)}`}>
            {category.childCategories.map(childCategory => (
              <div key={childCategory.categoryName} className="selected-products__sub-category-container">
                <div className="selected-products__sub-category">{childCategory.categoryName}</div>
                <div className="selected-products__products">
                  {childCategory.childProducts.map(childProduct => (
                    <ProductRow
                      key={childProduct.variantAgilityID}
                      isDark={true}
                      product={childProduct}
                      onChange={() => onChange(childProduct.variantAgilityID)}
                      isSelected={selectedIds.indexOf(childProduct.variantAgilityID) > -1}
                      quantity={getQuantity(childProduct.variantAgilityID)}
                      onUpdateQuantity={handleUpdateQuantity}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))
      }
    </Fragment >
  );
};

export default ProjectDetailTable;
