import React, { FunctionComponent, useState, Fragment, useEffect } from 'react';
import { States, Api } from '@core/types';
import ProjectRow from './ProjectRow';
import { projectActionCreators } from '@redux/projects';
import { connect } from 'react-redux';
import { usePassportContext } from '@passport/hooks';
import CreateProject from './CreateProject';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import useConstructor from '@hooks/useConstructor';

interface IProps {
  project: States.IProjectState;
  fetchProjects: (params: Api.IFetchProjectsRequest) => void;
}

const ProjectList: FunctionComponent<IProps> = ({ project, fetchProjects }) => {
  const [showCreateProject, setShowCreateProject] = useState(false);
  const { passportContext } = usePassportContext();
  useConstructor(() => fetchProjects({ bearerToken: passportContext.bearerToken || '' }));

  if (!project) {
    return null;
  }

  return (
    <Fragment>
      <div className="tabs">
        <div className="tabs__container">
          <button className="tab active">Previous Projects</button>
        </div>
        <div className="tabs__create">
          <button className="btn btn--inline" onClick={() => setShowCreateProject(true)}>Create New Project</button>
        </div>
      </div>
      <CreateProject visible={showCreateProject} setIsVisible={setShowCreateProject} />
      <div className="projects">
        <TransitionGroup>
          {project.projects.map(x => (
            <CSSTransition key={x.projectGUID} classNames="fade-in" timeout={300}>
              <ProjectRow key={x.projectGUID} project={x} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
});

const mapDispatchToProps = {
  fetchProjects: (params: Api.IBaseAuthorisedRequest) => projectActionCreators.fetchProjects(params),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
