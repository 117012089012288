import React, { FunctionComponent, useState, useRef } from 'react';
import ClassNames from 'classnames';
import { AppEnums } from '@core/enums';
import { connect } from 'react-redux';
import { States, Api, Models } from '@core/types';
import debounce from 'lodash/debounce';
import { productActionCreators } from '@redux/products';
import { usePassportContext } from '@passport/hooks';
import { ClaimType } from '@passport/enums';
import { projectActionCreators } from '@redux/projects';
import { ProductRow } from '@components/products';
import { appActionCreators } from '@redux/app';
import Simplebar from 'simplebar-react';


interface IProps {
  app?: States.IAppState;
  product?: States.IProductState;
  project?: States.IProjectState;
  searchProducts: (params: Api.IFetchProductsRequest) => void;
  updateProject: (params: Api.IUpdateProjectRequest) => void;
  setActiveNav: (navigation: string) => void;
}

const ProductSearch: FunctionComponent<IProps> = ({ app, project, product, searchProducts, updateProject, setActiveNav }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const doSearch = useRef(debounce(searchProducts, 275));

  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(ClaimType.Locality, passportContext.claims);

  if (!app || !project || !cultureClaim || passportContext.bearerToken === null || !product) {
    return null;
  }

  const handleOnChange = (value: string) => {
    // Set the internal state
    setSearchTerm(value);

    // return if we are less than 3 characters
    if (value.length <= 2) {
      return;
    }

    // Do the debounced api call
    doSearch.current({
      bearerToken: passportContext?.bearerToken || '',
      cultureCode: cultureClaim.value,
      searchTerm: value,
    });
  };

  const handleChange = (agilityID: number) => {
    const newState = [...selectedIds];
    const existingIndex = selectedIds.indexOf(agilityID);

    if (existingIndex > -1) {
      newState.splice(existingIndex, 1);
    } else {
      newState.push(agilityID);
    }

    setSelectedIds(newState);
  };

  const handleAddProducts = () => {
    if (!project.currentProject || !passportContext.bearerToken || !cultureClaim) {
      return;
    }

    const agilityIDs = [...selectedIds];
    const projectGUID = project.currentProject.projectGUID;

    const projectProducts: Models.ProjectProduct[] = [];

    // Add the existing products
    for (const projectProduct of project.currentProject.products) {
      projectProducts.push({
        productID: projectProduct.productID,
        quantity: projectProduct.quantity,
      });
    }

    // Add the new products
    for (const agilityID of agilityIDs) {
      projectProducts.push({
        productID: agilityID,
        quantity: 1,
      });
    }

    // Add all the products
    updateProject({
      projectGUID,
      projectProducts,
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      refetchProject: true,
    });

    // reset the selectedIDs
    setSelectedIds([]);

    // setset the search term
    setSearchTerm('');

    // close the navigation
    setActiveNav(AppEnums.Navigation.None);
  };

  const productSearchClassNames = ClassNames({
    'product-search': true,
    'is-active': app.activeNavigation === AppEnums.Navigation.Search,
  });

  return (
    <div className={productSearchClassNames}>
      <div className="product-search__container">
        <div className="product-search__search-container">
          <input
            type="text"
            id="txtSearch"
            placeholder="Search by Product Name or Article Number"
            className="product-search__search"
            value={searchTerm}
            onChange={e => handleOnChange(e.target.value)}
          ></input>
        </div>
        <Simplebar className="product-search__products-wrapper">
          <div className="product-search__products">
            {product.searchResults && product.searchResults.map(result => (
              <ProductRow
                key={`${result.variantAgilityID}`}
                isSelected={selectedIds.indexOf(result.variantAgilityID) > -1}
                product={result}
                onChange={handleChange}
              />))}
          </div>
        </Simplebar>
      </div>
      <div className="product-search__button">
        <button className="btn" onClick={handleAddProducts}>Add Products</button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  app: state.app,
  project: state.project,
  product: state.product,
});

const mapDispatchToProps = {
  updateProject: (params: Api.IUpdateProjectRequest) => projectActionCreators.updateProject(params),
  searchProducts: (params: Api.IFetchProductsRequest) => productActionCreators.searchProducts(params),
  setActiveNav: (navigation: string) => appActionCreators.setActiveNav(navigation),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSearch);
