import React, { FunctionComponent } from 'react';

interface IProps {
  className: string;
}

const Import: FunctionComponent<IProps> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.457"
    height="20.389"
    viewBox="0 0 22.457 20.389"
    className={className}
  >
    <g transform="translate(1.029)">
      <path
        d="M122.088,356.037v-2.08c.083-.007.159-.02.234-.02,1.468,0,2.936-.009,4.4,0a3.263,3.263,0,0,1,3.309,3.264q.033,4.108,0,8.216a3.256,3.256,0,0,1-3.3,3.271q-7.936.014-15.871,0a3.253,3.253,0,0,1-3.255-3.256q-.031-4.093,0-8.187a3.244,3.244,0,0,1,2.6-3.228,3.372,3.372,0,0,1,.7-.077c1.488-.007,2.975,0,4.463,0,.057,0,.114.01.186.016v2.085h-.5c-1.36,0-2.719,0-4.079,0a1.155,1.155,0,0,0-1.276,1.282q0,4,0,8.009a1.149,1.149,0,0,0,1.266,1.261q7.847,0,15.694,0a1.155,1.155,0,0,0,1.277-1.283q0-4.019,0-8.039a1.14,1.14,0,0,0-1.237-1.23c-1.428,0-2.857,0-4.285,0Z"
        transform="translate(-108.623 -348.306)"
        fill="#fff"
      />
      <path
        d="M205.294,272.344c0-1.964,0-3.855,0-5.745q0-3.294,0-6.589a1.035,1.035,0,1,1,2.068.012q0,5.939,0,11.878v.381c.113-.1.19-.171.262-.243q1.3-1.294,2.592-2.589a.991.991,0,0,1,1.083-.268,1.011,1.011,0,0,1,.7.869.956.956,0,0,1-.285.835q-2.346,2.355-4.7,4.7a.991.991,0,0,1-1.435,0q-2.361-2.34-4.7-4.7a1.039,1.039,0,1,1,1.5-1.436c.882.873,1.756,1.754,2.634,2.631C205.09,272.154,205.168,272.225,205.294,272.344Z"
        transform="translate(-196.608 -258.935)"
        fill="#fff"
      />
    </g>
  </svg>
);

export default Import;
