import { combineReducers, createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { appReducer } from '@redux/app';
import { projectReducer } from '@redux/projects';
import { productReducer } from '@redux/products';
import { adminReducer } from '@redux/admin';
import { translationReducer } from '@redux/translations';

const composeEnhancers = composeWithDevTools || compose;

export const history = createBrowserHistory();

const rootReducer = combineReducers({
  app: appReducer,
  admin: adminReducer,
  project: projectReducer,
  product: productReducer,
  translation: translationReducer,
  router: connectRouter(history),
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(routerMiddleware(history), reduxThunk)));

export default store;
