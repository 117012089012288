import React, { Fragment, FunctionComponent, useState } from 'react';
import { Dropdown } from '@components/shared';
import { Loader } from '@components/loaders';
import { States } from '@core/types';
import { ProjectEnums } from '@core/enums';
import classNames from 'classnames';

interface IProps {
  app: States.IAppState;
  project: States.IProjectState;
  isDownload: boolean;
  onSelectedSettings: (cultureCode: string, size: ProjectEnums.PDFSize) => void;
  onSubmit: () => void;
  onDownload: () => void;
}

const PdfSettings: FunctionComponent<IProps> = ({ app, project, onSubmit, onSelectedSettings, onDownload, isDownload }) => {
  const [hasViewed, setHasViewed] = useState(false);
  const [cultureCode, setCultureCode] = useState<string>('');
  const [pdfSize, setPdfSize] = useState<ProjectEnums.PDFSize>(ProjectEnums.PDFSize.A4);

  const handleSubmitInternal = () => {
    if (cultureCode === '') {
      return;
    }

    setHasViewed(false);

    onSelectedSettings(cultureCode, pdfSize);
  };

  const renderGenerate = () => {
    const buttonStyles = classNames({
      'btn': true,
      'btn--disabled': cultureCode === '',
    });

    return (
      <Fragment>
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Fact Tag Language</h2>
            <p className="align--center">Select which language you’d like your fact tags to be generated in and click download to generate your PDF.</p>
            <Dropdown
              placeholder="Select Language..."
              onChange={(value: string) => setCultureCode(value)}
              value={cultureCode}
              values={app.cultures.map(x => ({
                key: x.cultureCode,
                value: x.cultureName,
              }))}
            />
            <Dropdown
              onChange={(value: string) => setPdfSize(parseInt(value, 10) as ProjectEnums.PDFSize)}
              value={pdfSize.toString()}
              values={[
                {
                  key: ProjectEnums.PDFSize.A4.toString(),
                  value: 'A4',
                },
                {
                  key: ProjectEnums.PDFSize.A3.toString(),
                  value: 'A3',
                },
              ]}
            />
          </div>
        </div>
        <button className={buttonStyles} onClick={handleSubmitInternal} type="button">
          Create PDF
      </button>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {project.projectPDF === null && !project.pdfModal.isGenerating && (
        renderGenerate()
      )}
      {project.pdfModal.isGenerating && (
        <div className="form__inner">
          <div className="form__fields">
            <h2 className="align--center">Generating PDF</h2>
            <p className="align--center">
              Please wait whilst we create your PDF
              </p>
            <Loader />
          </div>
        </div>
      )}
      {project.projectPDF && !project.pdfModal.isGenerating && !isDownload && (
        <Fragment>
          <div className="form__inner">
            <div className="form__fields">
              <h2 className="align--center">Review your PDF</h2>
              <p className="align--center">You must view your PDF before sending to the printers</p>
              <p className="align--center">
                Click <a href={project.projectPDF.pdfUrl} onClick={() => setHasViewed(true)} target="_blank" rel="noopener noreferrer">here</a> to review your PDF
              </p>
            </div>
          </div>
          <button className={`btn ${!hasViewed ? 'btn--disabled' : ''}`} onClick={onSubmit} type="button">
            Send To Printers
          </button>
        </Fragment>
      )}
      {project.projectPDF && !project.pdfModal.isGenerating && isDownload && (
        <Fragment>
          <div className="form__inner">
            <div className="form__fields">
              <h2 className="align--center">Download your PDF</h2>
              <p className="align--center">Click below to download your PDF</p>
            </div>
          </div>
          <button className="btn" onClick={onDownload}>
            Download
          </button>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PdfSettings;
