import React, { FunctionComponent } from 'react';
import { IconConfirmation } from '@components/icons';
import { useParams, Link } from 'react-router-dom';
import { MdEmail } from 'react-icons/md';
import { connect } from 'react-redux';
import { States } from '@core/types';
import { RouterState } from 'connected-react-router';
import { AuthenticationRoutes } from '@components/routing';

interface IProps {
  router?: RouterState;
}

const ForgottenPassword: FunctionComponent<IProps> = ({ router }) => {
  if (!router || !router.location.state) {
    return null;
  }

  const emailAddress = router.location.state.emailAddress as string;

  return (
    <div className="form__inner form__inner--confirmation">
      <div className="confirmation">
        <IconConfirmation />
        <h2 className="confirmation__title">Thank You</h2>
        <p className="confirmation__text">
          An email has been sent to {emailAddress}
        </p>
        <p><Link to={AuthenticationRoutes.login.path}>Back to login</Link></p>
      </div>
    </div>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  router: state.router,
});

// const mapDispatchToProps = dispatch => ({
// });

export default connect(mapStateToProps, null)(ForgottenPassword);
