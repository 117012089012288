import React, { FunctionComponent, useState, Fragment } from 'react';
import { States, Api } from '@core/types';
import { connect } from 'react-redux';
import { usePassportContext } from '@passport/hooks';
import { ClaimType } from '@passport/enums';
import ProjectDetailFooter from './ProjectDetailFooter';
import { productActionCreators } from '@redux/products';
import useConstructor from '@hooks/useConstructor';
import useDestructor from '@hooks/useDestructor';
import { projectActionCreators } from '@redux/projects';
import { FullScreenLoader } from '@components/loaders';
import ProjectDetailTable from './ProjectDetailTable';
import { ProductService } from '@core/services';
import ProjectDetailTableControls from './ProjectDetailTableControls';
import { IndicationMessage } from '@components/shared';

interface IProps {
  product?: States.IProductState;
  project?: States.IProjectState;
  fetchProjectProducts: (params: Api.IFetchProductsRequest) => void;
  clearProject: () => void;
  updateProject: (params: Api.IUpdateProjectRequest) => void;
}

const ProjectDetail: FunctionComponent<IProps> = ({ project, product, fetchProjectProducts, clearProject, updateProject }) => {
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(ClaimType.Locality, passportContext.claims);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);

  // Request the products on load
  useConstructor(() => {
    if (!passportContext.bearerToken || !cultureClaim || !project || !project.currentProject || !product || product.currentCategories.length > 0) {
      return;
    }

    const variantAgilityIDs = project.currentProject.products.map(x => x.productID);

    fetchProjectProducts({
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      variantAgilityIDs,
    });
  });

  // Clear the current Project and the current Project Categories on leave
  useDestructor(() => {
    clearProject();
  });

  // Null checks
  if (!project || project.currentProject === null || !product) {
    return null;
  }

  // show the loader on first load (when currentCategories is 0)
  if (product.isLoadingCurrentCategories && product.currentCategories.length === 0) {
    return <FullScreenLoader message="Loading Project.." />;
  }

  // get all the products from the current categories
  const allProducts = ProductService.flatten(product.currentCategories);

  const setAll = (set: boolean) => {
    if (!set) {
      setSelectedIds([]);
      return;
    }

    const newState = [...allProducts.map(x => x.variantAgilityID)];

    setSelectedIds(newState);
  };

  const handleChange = (agilityID: number) => {
    const newState = [...selectedIds];
    const existingIndex = selectedIds.indexOf(agilityID);

    if (existingIndex > -1) {
      newState.splice(existingIndex, 1);
    } else {
      newState.push(agilityID);
    }

    setSelectedIds(newState);
  };

  const handleRemove = () => {
    if (!passportContext.bearerToken || !cultureClaim || selectedIds.length === 0 || !project.currentProject) {
      return;
    }

    const idsToKeep = allProducts
      .filter(x => selectedIds.indexOf(x.variantAgilityID) === -1)
      .map(x => ({
        productID: x.variantAgilityID,
        quantity: 1,
      }));

    updateProject({
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      projectGUID: project.currentProject.projectGUID,
      projectProducts: idsToKeep,
      refetchProject: true,
    });

    setAll(false);
  };

  return (
    <Fragment>
      <div className="selected-products">
        {project.currentProject.products.length === 0 && (
          <IndicationMessage>
            <Fragment>
              <p>
                You haven't added any products yet.
              </p>
              <p>
                Use the menu to search for products or import an existing list of article numbers.
            </p>
            </Fragment>
          </IndicationMessage>
        )}

        <ProjectDetailTableControls
          project={project}
          allSelected={selectedIds.length > 0 && allProducts.length === selectedIds.length}
          setAll={setAll}
          onRemove={handleRemove}
        />

        <ProjectDetailTable
          project={project}
          categories={product.currentCategories}
          selectedIds={selectedIds}
          onChange={handleChange}
          updateProject={updateProject}
        />
      </div>
      <ProjectDetailFooter />
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
  product: state.product,
});

const mapDispatchToProps = {
  updateProject: (params: Api.IUpdateProjectRequest) => projectActionCreators.updateProject(params),
  fetchProjectProducts: (params: Api.IFetchProductsRequest) => productActionCreators.fetchProjectProducts(params),
  clearProject: () => projectActionCreators.clearProject(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetail);
