import React, { FunctionComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Routes } from './Routes';
import { Landing, Project, Admin } from '@components/pages';
import { Styleguide } from '@components/styleguide';
import { usePassportContext } from '@passport/hooks';
import { States, Api } from '@core/types';
import { connect } from 'react-redux';
import { FullScreenLoader } from '@components/loaders';
import useConstructor from '@hooks/useConstructor';
import { productActionCreators } from '@redux/products';
import { ClaimType, RoleType } from '@passport/enums';
import { adminActionCreators } from '@redux/admin';
import { translationActionCreators } from '@redux/translations';

interface IProps {
  product?: States.IProductState;
  translation?: States.ITranslation;
  fetchNavigation: (bearerToken: string, cultureCode: string) => void;
  fetchPendingApprovals: (params: Api.IFetchPendingApprovalsRequest) => void;
  fetchTranslations: (cultureCode: string) => void;
}

const AppRouter: FunctionComponent<IProps> = ({ product, translation, fetchNavigation, fetchTranslations, fetchPendingApprovals }) => {
  const { passportContext, getClaim, getClaims } = usePassportContext();
  const cultureClaim = getClaim(ClaimType.Locality, passportContext.claims);
  const approvalClaims = getClaims(ClaimType.Role, passportContext.claims).filter(x => x.value.startsWith(RoleType.SentToPrintApprover));

  useConstructor(() => {
    if (!cultureClaim || !passportContext.bearerToken) {
      return;
    }

    // Fetch translations
    fetchTranslations(cultureClaim.value);

    // Fetch the navigation
    fetchNavigation(passportContext.bearerToken, 'en-TT');

    // Conditionally fetch the pending approvals
    if (approvalClaims) {
      const cultureCodes = approvalClaims.map(x => {
        // example: role.ftg.sent-to-print-approver.en-gb
        // Get the last index of '.'
        const index = x.value.lastIndexOf('.');

        // + 1 to skip the last '.' and return the culture code
        return x.value.substring(index + 1, x.value.length);
      });
      fetchPendingApprovals({ bearerToken: passportContext.bearerToken, cultureCodes });
    }
  });

  if (!product || !translation) {
    return null;
  }

  if (product.isLoadingNavigation) {
    return <FullScreenLoader message="Loading Products.." />;
  }

  if (translation.isLoading && !product.isLoadingNavigation) {
    return <FullScreenLoader message="Loading Translations.." />;
  }

  return (
    <Switch>
      <Route exact path={Routes.home.path} component={Landing} />
      <Route exact path={Routes.project.path} component={Project} />
      <Route exact path={Routes.styleguide.path} component={Styleguide} />

      {/* Conditionally add the admin route based on the users role so no unauthed users can directly visit /admin/ */}
      {approvalClaims.length > 0 && (
        <Route exact path={Routes.admin.path} component={Admin} />
      )}

      <Redirect to={Routes.home.path} />
    </Switch>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  product: state.product,
  translation: state.translation,
});

const mapDispatchToProps = {
  fetchNavigation: (bearerToken: string, cultureCode: string) => productActionCreators.fetchNavigation(bearerToken, cultureCode),
  fetchPendingApprovals: (params: Api.IFetchPendingApprovalsRequest) => adminActionCreators.fetchPendingApprovals(params),
  fetchTranslations: (cultureCode: string) => translationActionCreators.fetchTranslations(cultureCode),
};

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
