export enum ApiMethod {
  GET = 'GET',
  POST = 'POST',
  DELETE = 'DELETE',
}

export enum AuthenticationStatus {
  Success = 1,
  VerificationEmailSent = 2,
  AwaitingApproval = 3,
  ExplicitAccessOnly = 4,
}

export enum UserCreationStatus {
  AwaitingApproval = 1,
  VerificationEmailSent = 2,
}

export enum ClaimType {
  EmailAddress = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
  NameIdentifier = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
  UserGUID = 'UserGUID',
  Name = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
  Surname = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
  Locality = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/locality',
  Role = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
}

export enum RoleType {
  SentToPrintApprover = 'role.ftg.sent-to-print-approver',
  SiteFactTagGenerator = 'site.fact-tag-generator',
}

export enum Site {
  ProfileManager = 1,
  FactTagGenerator = 2,
  Passport = 3,
}
