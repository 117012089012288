import React, { FunctionComponent, useState } from 'react';
import { PassportService } from '@passport/services';
import { Site, UserCreationStatus } from '@passport/enums';
import { AuthenticationRoutes } from '@components/routing';
import { connect } from 'react-redux';
import { States } from '@core/types';
import { RouterState } from 'connected-react-router';
import { ApiError } from '@passport/components';
import { IApiError } from '@passport/types';
import { usePassportContext } from '@passport/hooks';
import { LoaderWrapper } from '@components/loaders';

interface IProps {
  redirect: (path: string) => void;
  router?: RouterState;
}

const NewUser: FunctionComponent<IProps> = ({ redirect, router }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [confirmedEmailAddress, setConfirmedEmailAddress] = useState(router && router.location.state && router.location.state.emailAddress);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<IApiError[]>([]);
  const { passportContext, setPassportContext } = usePassportContext();

  const onSubmit = async () => {
    // Trigger loaders
    setPassportContext({
      isCreatingUser: true,
    });

    const response = await PassportService.createUser({
      firstName,
      lastName,
      emailAddress: confirmedEmailAddress || '',
      password,
      confirmPassword,
      siteID: Site.FactTagGenerator,
      uiCultureCode: 'en-TT',
    });

    // Hide loaders after the api response
    setPassportContext({
      isCreatingUser: false,
    });

    if (response.errors.length > 0) {
      setErrors(response.errors);
    }

    if (response.data === null) {
      // TODO: something
      return;
    }

    // requires manual approval from higher up
    if (response.data.userCreationStatus === UserCreationStatus.AwaitingApproval) {
      redirect(AuthenticationRoutes.awaitingApproval.path);
    }

    // automatic approval using emails
    if (response.data.userCreationStatus === UserCreationStatus.VerificationEmailSent) {
      redirect(AuthenticationRoutes.awaitingVerification.path);
    }
  };


  return (
    <div className="form__inner">
      <LoaderWrapper isLoading={passportContext.isCreatingUser}>
        <div className="form__fields">
          <h2 className="align--center">You Don't have an account</h2>
          <p className="align--center">
            The email address you entered is not currently registered to an account. Please fill
            in the form below to register an account and gain access.
        </p>
          <div className="form__row">
            <input
              type="text"
              id="txtFirstName"
              placeholder="First Name"
              className="form__control"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
              onKeyDown={e => e.keyCode === 13 && onSubmit()}
            ></input>
            <label htmlFor="txtFirstName" className="form__label">
              First Name
          </label>
            <ApiError fieldName="firstName" errors={errors} />
          </div>
          <div className="form__row">
            <input
              type="text"
              id="txtLastName"
              placeholder="Last Name"
              className="form__control"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
              onKeyDown={e => e.keyCode === 13 && onSubmit()}
            ></input>
            <label htmlFor="txtLastName" className="form__label">
              Last Name
          </label>
            <ApiError fieldName="lastName" errors={errors} />
          </div>
          <div className="form__row">
            <input
              type="text"
              id="txtEmailAddress"
              placeholder="Email Address"
              className="form__control"
              value={confirmedEmailAddress}
              onChange={e => setConfirmedEmailAddress(e.target.value)}
              onKeyDown={e => e.keyCode === 13 && onSubmit()}
            ></input>
            <label htmlFor="txtEmailAddress" className="form__label">
              Email Address
          </label>
            <ApiError fieldName="emailAddress" errors={errors} />
          </div>
          <div className="form__row">
            <input
              type="password"
              id="txtPassword"
              placeholder="Password"
              className="form__control"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => e.keyCode === 13 && onSubmit()}
            ></input>
            <label htmlFor="txtPassword" className="form__label">
              Password
          </label>
            <ApiError fieldName="password" errors={errors} />
          </div>
          <div className="form__row">
            <input
              type="password"
              id="txtConfirmPassword"
              placeholder="Confirm Password"
              className="form__control"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              onKeyDown={e => e.keyCode === 13 && onSubmit()}
            ></input>
            <label htmlFor="txtPassword" className="form__label">
              Confirm Password
          </label>
            <ApiError fieldName="confirmPassword" errors={errors} />
          </div>
          {/* <div className="form__row">
            <div className="form__control form__dropdown">
              <div className="form__dropdown-selected">
                Language
            </div>
              <div className="form__dropdown-values">
                <div className="form__dropdown-value">Test 1</div>
                <div className="form__dropdown-value">Test 2</div>
                <div className="form__dropdown-value">Test 3</div>
                <div className="form__dropdown-value">Test 4</div>
                <div className="form__dropdown-value">Test 5</div>
                <div className="form__dropdown-value">Test 6</div>
              </div>
            </div>
          </div> */}
        </div>
        <button className="btn" type="button" onClick={onSubmit}>
          Register
      </button>
      </LoaderWrapper>
    </div>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  router: state.router,
});

// const mapDispatchToProps = dispatch => ({
// });

export default connect(mapStateToProps, null)(NewUser);
