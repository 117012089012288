interface IRoute {
  path: string;
  slug?: string;
}

interface IRoutes {
  styleguide: IRoute;
  home: IRoute;
  project: IRoute;
  admin: IRoute;
}

const Routes: IRoutes = {
  styleguide: {
    path: '/styleguide/',
  },
  home: {
    path: '/',
  },
  project: {
    path: '/project/:projectGUID',
    slug: '/project/',
  },
  admin: {
    path: '/admin/',
  },
};

interface IAuthenticationRoutes {
  login: IRoute;
  existingUser: IRoute;
  newUser: IRoute;
  forgottenPassword: IRoute;
  verify: IRoute;
  resetPassword: IRoute;
  awaitingApproval: IRoute;
  awaitingVerification: IRoute;
}

const AuthenticationRoutes: IAuthenticationRoutes = {
  login: {
    path: '/login/',
  },
  existingUser: {
    path: '/login/existing/',
  },
  newUser: {
    path: '/login/new/',
  },
  forgottenPassword: {
    path: '/login/forgotten-password/',
  },
  verify: {
    path: '/login/verify-account/:token',
  },
  resetPassword: {
    path: '/login/reset-password/:token',
  },
  awaitingApproval: {
    path: '/login/awaiting-approval/',
  },
  awaitingVerification: {
    path: '/login/awaiting-verification/',
  },
};

export { Routes, AuthenticationRoutes };
