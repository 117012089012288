import { Dispatch, AnyAction } from 'redux';
import {
  FETCH_PROJECT,
  FETCH_PROJECT_ERROR,
  FETCH_PROJECT_SUCCESS,
  FETCH_PROJECTS,
  FETCH_PROJECTS_ERROR,
  FETCH_PROJECTS_SUCCESS,
  CREATE_PROJECT,
  CREATE_PROJECT_ERROR,
  CREATE_PROJECT_SUCCESS,
  DELETE_PROJECT,
  DELETE_PROJECT_ERROR,
  DELETE_PROJECT_SUCCESS,
  UPDATE_PROJECT,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  GENERATE_PDF,
  GENERATE_PDF_ERROR,
  GENERATE_PDF_SUCCESS,
  SEND_FOR_APPROVAL,
  SEND_FOR_APPROVAL_ERROR,
  SEND_FOR_APPROVAL_SUCCESS,
  ProjectActionTypes,
  SET_PDF_MODAL,
  CLEAR_PDF,
  DOWNLOAD_PDF,
} from './actions';
import { ApiService } from '@core/services';
import { Api, Models, States } from '@core/types';
import { ThunkDispatch } from 'redux-thunk';
import { CLEAR_PROJECT } from '../shared';
import { fetchProjectProducts } from '../products/actioncreators';
import { ProjectEnums } from '@core/enums';

export const fetchProject = (params: Api.IFetchProjectRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_PROJECT,
  });

  // Do the api request
  const response = await ApiService.request<Api.IFetchProjectResponse>({
    controller: 'project',
    method: Api.Method.GET,
    slug: 'get-project',
    bearerToken: params.bearerToken,
    params: {
      projectGUID: params.projectGUID,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: FETCH_PROJECT_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_PROJECT_SUCCESS,
    payload: response.data,
  });
};

export const fetchProjects = (params: Api.IBaseAuthorisedRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_PROJECTS,
  });

  // Do the api request
  const response = await ApiService.request<Api.IFetchProjectsResponse>({
    controller: 'project',
    method: Api.Method.GET,
    slug: 'list-projects',
    bearerToken: params.bearerToken,
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: FETCH_PROJECTS_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_PROJECTS_SUCCESS,
    payload: response.data,
  });
};

export const createProject = (params: Api.ICreateProjectRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: CREATE_PROJECT,
  });

  // Do the api request
  const response = await ApiService.request<Api.ICreateProjectResponse>({
    controller: 'project',
    method: Api.Method.POST,
    slug: 'create-project',
    bearerToken: params.bearerToken,
    params: {
      projectName: params.projectName,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: CREATE_PROJECT_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the response of the creation success
  dispatch({
    type: CREATE_PROJECT_SUCCESS,
    payload: response.data,
  });

  // Refetch the products
  return dispatch(fetchProjects({ bearerToken: params.bearerToken }));
};

export const deleteProject = (params: Api.IDeleteProjectRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: DELETE_PROJECT,
  });

  // Do the api request
  const response = await ApiService.request<boolean | null>({
    controller: 'project',
    method: Api.Method.DELETE,
    slug: 'delete-project',
    bearerToken: params.bearerToken,
    params: {
      projectGUID: params.projectGUID,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: DELETE_PROJECT_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the response of the deletion success
  dispatch({
    type: DELETE_PROJECT_SUCCESS,
    payload: response.data,
  });

  // Refetch the products
  return dispatch(fetchProjects({ bearerToken: params.bearerToken }));
};

export const updateProject = (params: Api.IUpdateProjectRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: UPDATE_PROJECT,
  });

  // Do the api request
  const response = await ApiService.request<boolean | null>({
    controller: 'project',
    method: Api.Method.PUT,
    slug: 'update-project-products',
    bearerToken: params.bearerToken,
    params: {
      projectGUID: params.projectGUID,
      projectProducts: params.projectProducts,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: UPDATE_PROJECT_ERROR,
      payload: response.errors,
    });
  }

  // dispatch a success
  dispatch({
    type: UPDATE_PROJECT_SUCCESS,
    payload: params,
  });

  if (params.refetchProject === true) {
    // Refetch the project products
    const variantAgilityIDs = params.projectProducts.map(x => x.productID);

    dispatch(fetchProjectProducts({ bearerToken: params.bearerToken, cultureCode: params.cultureCode, variantAgilityIDs }));
  }
};

export const generatePDF = (params: Api.IGeneratePDFRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: GENERATE_PDF,
  });

  // Do the api request
  const response = await ApiService.request<Api.IGeneratePDFRequest>({
    controller: 'project',
    method: Api.Method.POST,
    slug: 'generate-pdf-from-project',
    bearerToken: params.bearerToken,
    params: {
      projectGUID: params.projectGUID,
      cultureCode: params.cultureCode,
      size: params.size,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: GENERATE_PDF_ERROR,
      payload: response.errors,
    });
  }

  // dispatch a success
  return dispatch({
    type: GENERATE_PDF_SUCCESS,
    payload: response.data,
  });
};

export const SendForApproval = (params: Api.ISendForApprovalRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: SEND_FOR_APPROVAL,
  });

  // Do the api request
  const response = await ApiService.request<boolean>({
    controller: 'project',
    method: Api.Method.GET,
    slug: 'send-pdf-for-approval',
    bearerToken: params.bearerToken,
    params: {
      projectPDFID: params.projectPDFID,
      cultureCode: params.cultureCode,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: SEND_FOR_APPROVAL_ERROR,
      payload: response.errors,
    });
  }

  // dispatch a success
  dispatch({
    type: SEND_FOR_APPROVAL_SUCCESS,
    payload: response.data,
  });

  return dispatch(clearPDF());
};

export function clearProject() {
  return {
    type: CLEAR_PROJECT,
  };
}

export function setPDFModal(settings: Models.SetPDFModal): ProjectActionTypes {
  return {
    type: SET_PDF_MODAL,
    payload: settings,
  };
}

export const clearPDF = () => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: CLEAR_PDF,
  });

  return dispatch(
    setPDFModal({
      isGenerating: false,
      isOpen: false,
      isSendingForApproval: false,
      pdfType: ProjectEnums.PDFType.Unset,
    }),
  );
};

export const downloadPDF = (url: string) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  dispatch({
    type: DOWNLOAD_PDF,
  });

  // Download the URL using an anchor tag
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = 'FactTags.pdf';
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.remove();

  dispatch(clearPDF());

  return dispatch(
    setPDFModal({
      isGenerating: false,
      isOpen: false,
      isSendingForApproval: false,
      pdfType: ProjectEnums.PDFType.Unset,
    }),
  );
};
