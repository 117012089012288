import React, { FunctionComponent } from 'react';

interface IProps {
  className?: string;
}

const Logo: FunctionComponent<IProps> = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="47" height="21" viewBox="0 0 47 21">
    <path id="Path_3893" data-name="Path 3893" d="M0,21l19.484-7.483-.927,1.921,16.778-5.73-.72,1.551L47,6.56,30.535,14.9l1.036-2.012-18.136,6.75,1.2-2.306ZM3.19,15.194l1.1-9.244L0,8.009,6.283,2.717,6.033,5.189,7.864,3.637l.92,1.034,2.147-1.81L12,4.041,10.841,14.046,9.422,13l.965-8.222-.264-.26L8.89,5.561c-.214,1.822-1.109,9.465-1.119,9.547-.086-.058-1.42-1.054-1.42-1.054l1-8.431-.265-.262L5.842,6.41v.019c-.057.46-1.119,8.927-1.229,9.809C4.5,16.164,3.19,15.194,3.19,15.194Zm8.777-2.617.685-5.271L14.137,7l-.616,4.547.368.3.738-1.016.9-7.462,1.452-.235-.96,7.766.435.352.617-.837.5-4.128,1.488-.311L18.5,10.522l.468.414.439-.582.57-4.509,1.483-.362L20.9,9.818l.557.463.327-.354.6-4.609,1.188-.257-.591,4.406-1.923,2.089-1.111-.978-1.368,1.81-1.385-1.206L15.9,12.94l-1.344-1.074L13.272,13.6Zm11.5-3.115.417-3.293L25.33,4.326l1.029.5.033-.314,1.474-.316-.509,4.035.3.263.683-.868.457-3.614,1.475-.294-.491,3.864.275.242.653-.787.459-3.525,1.464-.334-.494,3.9.194.163.824-.9L33.911.188,35.271,0l-.45,3.586,1.882-1.6,1.136-.163L35.338,4.1l1.115,2.2.71-.687L37.5,2.988,39.094,1.63l1.747,2.11L38.672,4.934l-.1.745.468.383L41,4.985l.345-2.767L42.811.936l1.608,1.992L42.424,4.057l-.088.7.428.358,2.2-1.244-.076.595L42.128,6.084,41.36,5.42l-3.009,1.66-1.008-.849L35.787,7.747,34.615,5.342l-.337,2.7-1.115-.884L31.7,8.748l-1.08-.939L29.482,9.3,28.45,8.39l-1.2,1.543L26.145,9,24.863,10.65Zm1.84-.38.6-.735L26.28,5.4l-.8-.427-.488,3.835ZM38.772,4.151l.87-.478-.7-.815Zm3.743-.834.8-.452L42.669,2.1ZM12.935,4.96l1.483-.251-.187,1.423-1.481.28ZM43.623.908a.609.609,0,1,1,.612.6A.6.6,0,0,1,43.623.908Zm.122,0a.487.487,0,1,0,.974,0,.487.487,0,1,0-.974,0Zm.667.349-.184-.3h-.116v.3H44V.566h.265c.164,0,.245.059.245.2a.178.178,0,0,1-.18.19l.2.306Zm-.3-.39h.125c.087,0,.168-.006.168-.113,0-.086-.078-.1-.15-.1h-.143Z" transform="translate(0 0)" fill="#fff" />
  </svg>
);

export default Logo;
