import React, { Fragment, useState, FunctionComponent, Dispatch } from 'react';
import { PassportService } from '@passport/services';
import { AuthenticationRoutes } from '@components/routing';
import { IApiError } from '@passport/types';
import { ApiError } from '@passport/components';
import { LoaderWrapper } from '@components/loaders';
import { usePassportContext } from '@passport/hooks';

interface IProps {
  redirect: (path: string, state: {}) => void;
}

const CheckUser: FunctionComponent<IProps> = ({ redirect }) => {
  const [emailAddress, setEmailAddress] = useState('');
  const [errors, setErrors] = useState<IApiError[]>([]);
  const { passportContext, setPassportContext } = usePassportContext();

  const checkUserExists = async () => {
    // Trigger loaders
    setPassportContext({
      isCheckingUser: true,
    });

    // Do the api request
    const response = await PassportService.checkUserExists({
      emailAddress,
    });

    // No longer checking user after the response
    setPassportContext({
      isCheckingUser: false,
    });

    // Handle errors
    if (response.errors.length > 0) {
      setErrors(response.errors);
      return;
    }

    if (response.data === null) {
      redirect(AuthenticationRoutes.newUser.path, { emailAddress });
      return;
    }

    redirect(AuthenticationRoutes.existingUser.path, { emailAddress });
  };

  return (
    <div className="form__inner">
      <LoaderWrapper isLoading={passportContext.isCheckingUser}>
        <div className="form__fields">
          <h2 className="align--center">Gain access</h2>
          <p className="align--center">
            Enter your email address below to gain access to the Fact Tag Generator
        </p>
          <div className="form__row">
            <input
              type="text"
              id="txtEmailAddress"
              placeholder="Your Email Address..."
              className="form__control"
              value={emailAddress}
              onChange={e => setEmailAddress(e.target.value)}
              onKeyDown={e => e.keyCode === 13 && checkUserExists()}
            ></input>
            <label htmlFor="txtEmailAddress" className="form__label">
              Email Address
          </label>
            <ApiError fieldName="emailAddress" errors={errors} />
          </div>
        </div>
        <button className="btn" type="button" onClick={checkUserExists}>
          Submit
      </button>
      </LoaderWrapper>
    </div>
  );
};

export default CheckUser;
