import React, { FunctionComponent, useState } from 'react';
import ClassNames from 'classnames';
import { AppEnums } from '@core/enums';
import { States, Models, Api } from '@core/types';
import { connect } from 'react-redux';
import { ProductRow } from '@components/products';
import { projectActionCreators } from '@redux/projects';
import { usePassportContext } from '@passport/hooks';
import { appActionCreators } from '@redux/app';
import { ClaimType } from '@passport/enums';
import m12 from '@img/m12.png';
import m18 from '@img/m18.png';
import Simplebar from 'simplebar-react';

interface IProps {
  app?: States.IAppState;
  project?: States.IProjectState;
  category: Models.Category | null;
  updateProject: (params: Api.IUpdateProjectRequest) => void;
  setActiveNav: (navigation: string) => void;
  navigationID: AppEnums.Navigation;
}

const ProductSelect: FunctionComponent<IProps> = ({ app, project, category, updateProject, setActiveNav, navigationID }) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(ClaimType.Locality, passportContext.claims);

  if (!app || !category || !project || project.currentProject === null) {
    return null;
  }

  const productSelectClassNames = ClassNames({
    'product-select': true,
    'is-active': app.activeNavigation === navigationID,
  });

  const handleChange = (agilityID: number) => {
    const newState = [...selectedIds];
    const existingIndex = selectedIds.indexOf(agilityID);

    if (existingIndex > -1) {
      newState.splice(existingIndex, 1);
    } else {
      newState.push(agilityID);
    }

    setSelectedIds(newState);
  };

  const handleAddProducts = () => {
    if (!project.currentProject || !passportContext.bearerToken || !cultureClaim) {
      return;
    }

    const agilityIDs = [...selectedIds];
    const projectGUID = project.currentProject.projectGUID;

    const projectProducts: Models.ProjectProduct[] = [];

    // Add the existing products
    for (const product of project.currentProject.products) {
      projectProducts.push({
        productID: product.productID,
        quantity: product.quantity,
      });
    }

    // Add the new products
    for (const agilityID of agilityIDs) {
      projectProducts.push({
        productID: agilityID,
        quantity: 1,
      });
    }

    // Add all the products
    updateProject({
      projectGUID,
      projectProducts,
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      refetchProject: true,
    });

    // reset the selectedIDs
    setSelectedIds([]);

    // close the navigation
    setActiveNav(AppEnums.Navigation.None);
  };

  const renderTitle = () => {
    if (navigationID === AppEnums.Navigation.M12) {
      return <img alt="M12" src={m12} />;
    }

    if (navigationID === AppEnums.Navigation.M18) {
      return <img alt="M18" src={m18} />;
    }

    return category.categoryName;
  };

  return (
    <div className={productSelectClassNames}>
      <Simplebar className="product-select__container">
        <div className="product-select__category">
          {renderTitle()}
        </div>
        {category.childCategories.map(c => (
          <div key={c.categoryName} className="product-select__sub-category-container">
            <div className="product-select__sub-category">{c.categoryName}</div>
            <div className="product-select__products">
              {c.childProducts.map(p =>
                <ProductRow
                  key={p.variantAgilityID}
                  isSelected={selectedIds.indexOf(p.variantAgilityID) > -1}
                  product={p}
                  onChange={handleChange}
                />)
              }
            </div>
          </div>
        ))}
      </Simplebar>
      <div className="product-select__button">
        <button className="btn" onClick={handleAddProducts}>Add Products</button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  project: state.project,
  app: state.app,
});

const mapDispatchToProps = {
  updateProject: (params: Api.IUpdateProjectRequest) => projectActionCreators.updateProject(params),
  setActiveNav: (navigation: string) => appActionCreators.setActiveNav(navigation),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSelect);
