import { AppEnums } from '@core/enums';

export const SET_NAVIGATION = 'APP/SET_NAVIGATION';

export interface ISetNavigation {
  type: typeof SET_NAVIGATION;
  payload: string;
}

export type AppActionTypes = ISetNavigation;
