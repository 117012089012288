import Cookies from 'js-cookie';
import { IStorage } from './types';

export const cookieKeys = {};

class CookieStorage implements IStorage {
  /**
   * Gets a value using the given key from cookies
   * @param {string} key
   * @returns undefined if not found
   */
  get<T>(key: string): T | undefined {
    const value = Cookies.get(key);

    if (value === undefined) {
      return undefined;
    }

    return JSON.parse(value) as T;
  }

  /**
   * Sets a cookie.
   * @param {string} key
   * @param {*} value
   */
  set(key: string, value: any) {
    Cookies.set(key, JSON.stringify(value));
  }

  /**
   * Deletes a cookie.
   * @param key
   */
  delete(key: string) {
    Cookies.remove(key);
  }
}

export default new CookieStorage();
