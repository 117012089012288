import { Api } from '@core/types';

export const FETCH_PENDING_APPROVALS = 'ADMIN/FETCH_PENDING_APPROVALS';
export const FETCH_PENDING_APPROVALS_SUCCESS = 'ADMIN/FETCH_PENDING_APPROVALS_SUCCESS';
export const FETCH_PENDING_APPROVALS_ERROR = 'ADMIN/FETCH_PENDING_APPROVALS_ERROR';

export const PRINTER_APPROVE_REJECT = 'ADMIN/PRINTER_APPROVE_REJECT';
export const PRINTER_APPROVE_REJECT_SUCCESS = 'ADMIN/PRINTER_APPROVE_REJECT_SUCCESS';
export const PRINTER_APPROVE_REJECT_ERROR = 'ADMIN/PRINTER_APPROVE_REJECT_ERROR';

export interface IFetchPendingApprovals {
  type: typeof FETCH_PENDING_APPROVALS;
}

export interface IFetchPendingApprovalsSuccess {
  type: typeof FETCH_PENDING_APPROVALS_SUCCESS;
  payload: Api.IFetchPendingApprovalsResponse;
}

export interface IFetchPendingApprovalsError {
  type: typeof FETCH_PENDING_APPROVALS_ERROR;
  payload: Api.IApiError[];
}

export interface IPrinterApproveReject {
  type: typeof PRINTER_APPROVE_REJECT;
}

export interface IPrinterApproveRejectSuccess {
  type: typeof PRINTER_APPROVE_REJECT_SUCCESS;
  payload: boolean;
}

export interface IPrinterApproveRejectError {
  type: typeof PRINTER_APPROVE_REJECT_ERROR;
  payload: Api.IApiError[];
}

export type AdminActionTypes = IFetchPendingApprovals | IFetchPendingApprovalsSuccess | IFetchPendingApprovalsError;
