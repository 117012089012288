import { Models } from '@core/types';

class ProductService {
  /**
   * Flattens the given categories into products
   */
  flatten(categories: Models.Category[]): Models.Product[] {
    const products: Models.Product[] = [];

    for (const category of categories) {
      for (const childCategory of category.childCategories) {
        for (const product of childCategory.childProducts) {
          if (products.findIndex(x => x.variantAgilityID === product.variantAgilityID) > -1) {
            continue;
          }

          products.push(product);
        }
      }
    }

    return products;
  }

  /**
   * Finds and returns the missing products using an article number lookup
   * @param categories The Categories
   * @param articleNumbers The Article Numbers
   */
  findMissingArticleNumbers(products: Models.Product[], articleNumbers: string[]): string[] {
    // Find the unmatched products
    const unmatchedProducts: string[] = [];

    for (const articleNumber of articleNumbers) {
      if (products.findIndex(x => x.articleNumbers.indexOf(articleNumber) > -1) > -1) {
        continue;
      }

      unmatchedProducts.push(articleNumber);
    }

    return unmatchedProducts;
  }
}

export default new ProductService();
