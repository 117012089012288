import React, { FunctionComponent, Fragment } from 'react';
import { IApiError } from '@passport/types';

interface IProps {
  fieldName: string;
  errors: IApiError[];
}

const ApiError: FunctionComponent<IProps> = ({ fieldName, errors }) => {
  if (errors.length === 0) {
    return null;
  }

  const error = errors.find(x => x.fieldName.toLowerCase() === fieldName.toLowerCase());

  if (error === undefined) {
    return null;
  }

  return <p className="error">{error.message}</p>;

};

export default ApiError;
