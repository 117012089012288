import React, { FunctionComponent, useState, Fragment } from 'react';
import { PassportService } from '@passport/services';
import { Site, AuthenticationStatus } from '@passport/enums';
import { Routes, AuthenticationRoutes } from '@components/routing';
import { usePassportContext } from '@passport/hooks';
import { States } from '@core/types';
import { connect } from 'react-redux';
import { RouterState } from 'connected-react-router';
import { IApiError } from '@passport/types';
import { ApiError, Error } from '@passport/components';
import { LoaderWrapper } from '@components/loaders';

interface IProps {
  redirect: (path: string, state?: {}) => void;
  router?: RouterState;
}

const ExistingUser: FunctionComponent<IProps> = ({ redirect, router }) => {
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<IApiError[]>([]);
  const [authenticationStatus, setAuthenticationStatus] = useState<AuthenticationStatus>();
  const { passportContext, setPassportContext } = usePassportContext();

  if (!router || !router.location.state) {
    return null;
  }

  const emailAddress = router.location.state.emailAddress as string;

  if (!emailAddress) {
    redirect(AuthenticationRoutes.login.path);
    return null;
  }

  const authenticate = async () => {
    setAuthenticationStatus(undefined);

    // Set loaders
    setPassportContext({
      isLogginIn: true,
    });

    const response = await PassportService.authenticate({
      emailAddress,
      password,
      siteID: Site.FactTagGenerator
    });

    // Handle errors
    if (response.errors.length > 0) {
      setErrors(response.errors);
    } else {
      setErrors([]);
    }

    // Handle no response
    if (response.data === null) {
      setPassportContext({
        isLogginIn: false,
      });
      return;
    }

    // Set the data in the context
    setPassportContext({
      isValidating: false,
      claims: response.data.claims,
      bearerToken: response.data.bearerToken,
      tokenExpiration: response.data.tokenExpiration,
      authenticationStatus: response.data.authenticationStatus,
      isLogginIn: false,
    });

    switch (response.data.authenticationStatus) {
      case AuthenticationStatus.Success: {
        redirect(Routes.home.path);
        break;
      }

      case AuthenticationStatus.VerificationEmailSent:
      case AuthenticationStatus.AwaitingApproval: {
        setAuthenticationStatus(response.data.authenticationStatus);
        break;
      }

      default: {
        redirect(Routes.home.path);
        break;
      }
    }
  };

  const showResetPassword = (e: React.MouseEvent) => {
    PassportService.forgottenPassword({ emailAddress, siteID: Site.FactTagGenerator });
    e.preventDefault();
    redirect(AuthenticationRoutes.forgottenPassword.path, { emailAddress });
  };

  const handleResendVerification = () => {
    setAuthenticationStatus(undefined);
    PassportService.resendVerificationEmail({
      emailAddress,
      siteID: Site.FactTagGenerator,
    });
  };

  const renderAuthenticationErrors = () => {
    if (!authenticationStatus) {
      return null;
    }


    if (authenticationStatus === AuthenticationStatus.AwaitingApproval) {
      return (
        <Fragment>
          <Error center={true} message="Your account is still waiting for approval, please speak to your administrator" />
        </Fragment>
      );
    }

    if (authenticationStatus === AuthenticationStatus.VerificationEmailSent) {
      return (
        <Fragment>
          <Error center={true} message="We've sent you a verification email in order to complete your account creation. Not received the email?">
            <button className="btn btn--link" onClick={handleResendVerification}>Click here</button>
          </Error>
        </Fragment>
      );
    }

    return null;
  };

  // Render form
  return (
    <div className="form__inner">
      <LoaderWrapper isLoading={passportContext.isLoggingIn}>
        <div className="form__fields">
          <h2 className="align--center">You have an account</h2>
          <p className="align--center">{emailAddress}</p>
          <p className="align--center">
            The above email address already has an account. Enter your password below to sign
            in.
        </p>
          {renderAuthenticationErrors()}
          <p className="align--center">
          </p>
          <div className="form__row">
            <input
              type="password"
              id="txtPassword"
              placeholder="Your password…"
              className="form__control"
              value={password}
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => e.keyCode === 13 && authenticate()}
            ></input>
            <label htmlFor="txtPassword" className="form__label">
              Password
          </label>
            <ApiError fieldName="password" errors={errors} />
          </div>
        </div>
        <button className="btn" type="button" onClick={authenticate}>
          Sign In
      </button>
        <div className="form__forgotten-password">
          Forgotten your password?
        <a onClick={showResetPassword} href="">Retrieve password</a>
        </div>
      </LoaderWrapper>
    </div>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  router: state.router,
});

// const mapDispatchToProps = dispatch => ({
// });

export default connect(mapStateToProps, null)(ExistingUser);
