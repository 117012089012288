const keys: string[] = [
  'back',
  'packout.yoursystem',
  'packout.noproducts',
  'SubmitEntry',
  'packout.dreampackout',
  'restart',
  'packout',
  'AddProducts',
  'packout.firstload',
  'packout.loadingproducts',
  'packout.rollers',
  'packout.bags',
  'packout.boxes',
  'packout.organisers',
  'packout.submitting',
  'packout.submitted',
];

export default keys;
