import { States } from '@core/types';
import { AdminActionTypes, FETCH_PENDING_APPROVALS, FETCH_PENDING_APPROVALS_SUCCESS, FETCH_PENDING_APPROVALS_ERROR } from './actions';

const initialState: States.IAdminState = {
  pendingPDFs: [],
  isLoadingPendingPDFs: false,
  errors: [],
};

function adminReducer(state: States.IAdminState = initialState, action: AdminActionTypes): States.IAdminState {
  switch (action.type) {
    case FETCH_PENDING_APPROVALS: {
      const newState = { ...state };

      newState.isLoadingPendingPDFs = true;

      return newState;
    }

    case FETCH_PENDING_APPROVALS_SUCCESS: {
      const newState = { ...state };

      newState.pendingPDFs = action.payload.projectPDFs;
      newState.isLoadingPendingPDFs = false;

      return newState;
    }

    case FETCH_PENDING_APPROVALS_ERROR: {
      const newState = { ...state };

      newState.errors = action.payload;

      return newState;
    }

    default:
      return state;
  }
}

export default adminReducer;
