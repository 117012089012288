import React, { useState, FunctionComponent } from 'react';
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { AuthenticationRoutes } from './Routes';
import {
  ForgottenPassword,
  VerifyAccount,
  ResetPassword,
  CheckUser,
  ExistingUser,
  NewUser,
  AwaitingApproval,
  AwaitingVerification
} from '@components/authentication';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

interface IProps {
  redirect: (path: string, state?: {}) => void;
}

const LoginRouter: FunctionComponent<IProps> = ({ redirect }) => (
  <main className="main--full-screen">
    <div className="site-wide">
      <div className="form">
        <Switch>
          <Route
            exact
            path={AuthenticationRoutes.login.path}
            component={() => <CheckUser redirect={redirect} />}
          />

          <Route
            exact
            path={AuthenticationRoutes.existingUser.path}
            component={() => <ExistingUser redirect={redirect} />}
          />

          <Route
            exact
            path={AuthenticationRoutes.newUser.path}
            component={() => <NewUser redirect={redirect} />}
          />

          <Route
            exact
            path={AuthenticationRoutes.forgottenPassword.path}
            component={() => <ForgottenPassword />}
          />

          <Route exact path={AuthenticationRoutes.verify.path} component={() => <VerifyAccount />} />
          <Route exact path={AuthenticationRoutes.resetPassword.path} component={() => <ResetPassword />} />
          <Route exact path={AuthenticationRoutes.awaitingApproval.path} component={() => <AwaitingApproval />} />
          <Route exact path={AuthenticationRoutes.awaitingVerification.path} component={() => <AwaitingVerification />} />

          <Redirect to={AuthenticationRoutes.login.path} />
        </Switch>
      </div>
    </div>
  </main>
);


// const mapStateToProps = state => ({
// });

const mapDispatchToProps = {
  redirect: (path: string, state?: {}) => push(path, state),
};

export default connect(null, mapDispatchToProps)(LoginRouter);
