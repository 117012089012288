import { Api, Models } from '@core/types';
import { Dispatch, AnyAction } from 'redux';
import {
  FETCH_PENDING_APPROVALS,
  FETCH_PENDING_APPROVALS_ERROR,
  FETCH_PENDING_APPROVALS_SUCCESS,
  PRINTER_APPROVE_REJECT,
  PRINTER_APPROVE_REJECT_ERROR,
  PRINTER_APPROVE_REJECT_SUCCESS,
} from './actions';
import { ApiService } from '@core/services';
import { ThunkDispatch } from 'redux-thunk';

async function fetchPendingApprovalItem(bearerToken: string, cultureCode: string) {
  console.log(cultureCode);
  const response = await ApiService.request<Api.IFetchPendingApprovalsResponse>({
    controller: 'project',
    method: Api.Method.GET,
    slug: 'list-pdfs-awaiting-approval',
    bearerToken,
    params: {
      cultureCode,
    },
  });

  return response;
}

export const fetchPendingApprovals = (params: Api.IFetchPendingApprovalsRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_PENDING_APPROVALS,
  });

  const requests = [];
  const approvals: Models.ProjectPDF[] = [];

  for (const cultureCode of params.cultureCodes) {
    const response = fetchPendingApprovalItem(params.bearerToken, cultureCode);
    requests.push(response);
  }

  const results = await Promise.all(requests);

  for (const response of results) {
    // Dispatch any errors
    if (!response.success || response.errors.length > 0 || !response.data) {
      return dispatch({
        type: FETCH_PENDING_APPROVALS_ERROR,
        payload: response.errors,
      });
    }

    approvals.push(...response.data.projectPDFs);
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_PENDING_APPROVALS_SUCCESS,
    payload: {
      projectPDFs: approvals,
    },
  });
};

export const printerApproveReject = (params: Api.IPrinterApproveReject) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: PRINTER_APPROVE_REJECT,
  });

  // Do the api request
  const response = await ApiService.request<boolean>({
    controller: 'project',
    method: Api.Method.POST,
    slug: 'printer-approve-reject',
    bearerToken: params.bearerToken,
    params: {
      projectPDFID: params.projectPDFID,
      acceptReject: params.acceptReject,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: PRINTER_APPROVE_REJECT_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response data
  dispatch({
    type: PRINTER_APPROVE_REJECT_SUCCESS,
    payload: response.data,
  });

  // refetch the pending pdfs to update the list
  const pendingApprovalParams: Api.IFetchPendingApprovalsRequest = {
    bearerToken: params.bearerToken,
    cultureCodes: [params.cultureCode],
  };

  return dispatch(fetchPendingApprovals(pendingApprovalParams));
};
