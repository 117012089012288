import React, { FunctionComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { MdSearch, MdExitToApp, MdHome } from 'react-icons/md';
import { Logo, Import, Verified } from '@components/icons';
import { Routes } from '@components/routing';
import { AppEnums } from '@core/enums';
import { appActionCreators } from '@redux/app';
import { States, Models } from '@core/types';
import { usePassportContext } from '@passport/hooks';
import m12 from '@img/m12.png';
import m18 from '@img/m18.png';
import { ClaimType, RoleType } from '@passport/enums';
import { push } from 'connected-react-router';

interface IProps {
  app: States.IAppState;
  product: States.IProductState;
  project: States.IProjectState;
  admin: States.IAdminState;
  setNavigation: (navigation: string) => void;
  redirect: (path: string, state?: {}) => void;
}

const Navigation: FunctionComponent<IProps> = ({ app, admin, product, project, setNavigation, redirect }) => {
  const { passportContext, getClaims, logOut } = usePassportContext();
  const approvalClaim = getClaims(ClaimType.Role, passportContext.claims).find(x => x.value.startsWith(RoleType.SentToPrintApprover));

  if (!app) {
    return null;
  }

  const handleSetNavigation = (e: React.MouseEvent, navigation: string) => {
    if (app.activeNavigation === navigation) {
      setNavigation(AppEnums.Navigation.None);
      return;
    }

    setNavigation(navigation);
  };

  const getItemClasses = (navigationID: AppEnums.Navigation) => {
    let className = 'navigation__text';

    if (app.activeNavigation === navigationID) {
      className += ' active';
    }

    return className;
  };

  const renderCategory = (category: Models.Category | null, navigationID: AppEnums.Navigation) => {
    if (category === null) {
      return null;
    }

    let tab = <Fragment>{category.categoryName}</Fragment>;

    if (navigationID === AppEnums.Navigation.M12) {
      tab = <img alt="M12" src={m12} />;
    }

    if (navigationID === AppEnums.Navigation.M18) {
      tab = <img alt="M18" src={m18} />;
    }

    return (
      <div className={getItemClasses(navigationID)} onClick={(e) => handleSetNavigation(e, navigationID)}>
        {tab}
      </div>
    );
  };


  return (
    <nav className="navigation">
      <Link to={Routes.home.path}>
        <div className="navigation-logo">
          <Logo />
        </div>
      </Link>
      <div className="navigation__content">
        <div className="navigation__icon active" onClick={() => redirect(Routes.home.path)}>
          <MdHome />
        </div>
        {/* Show project related icons when we have a project active */}
        {project.currentProject !== null && (
          <Fragment>
            {renderCategory(product.cordless, AppEnums.Navigation.Cordless)}
            {renderCategory(product.corded, AppEnums.Navigation.Corded)}
            {renderCategory(product.m18, AppEnums.Navigation.M18)}
            {renderCategory(product.m12, AppEnums.Navigation.M12)}
            <div className={getItemClasses(AppEnums.Navigation.Import)} onClick={(e) => handleSetNavigation(e, AppEnums.Navigation.Import)}>
              <Import className="navigation__text--icon" />
              Import
            </div>
            <div className={getItemClasses(AppEnums.Navigation.Search)} onClick={(e) => handleSetNavigation(e, AppEnums.Navigation.Search)}>
              <MdSearch className="navigation__text--icon" />
              Search
          </div>
          </Fragment>
        )}
      </div>
      <div className="navigation__content navigation__content--bottom">
        {/* Show user specific icons based on roles */}
        {approvalClaim && admin && (
          <div className={`navigation__icon ${admin.pendingPDFs.length > 0 ? 'active' : ''}`} onClick={() => redirect(Routes.admin.path)}>
            <Verified />
            {admin.pendingPDFs.length > 0 && (
              <div className="navigation__icon-tooltip">{admin.pendingPDFs.length}</div>
            )}
          </div>
        )}
        <div className="navigation__icon" onClick={logOut}>
          <MdExitToApp />
        </div>
      </div>
    </nav>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  app: state.app,
  product: state.product,
  project: state.project,
  admin: state.admin,
});

const mapDispatchToProps = {
  setNavigation: (navigation: string) => appActionCreators.setActiveNav(navigation),
  redirect: (path: string, state?: {}) => push(path, state),
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
