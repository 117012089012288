import React, { FunctionComponent } from 'react';
import { Models, Api } from '@core/types';
import { Close, Verified } from '@components/icons';
import { connect } from 'react-redux';
import { adminActionCreators } from '@redux/admin';
import { ClaimType } from '@passport/enums';
import { usePassportContext } from '@passport/hooks';

interface IProps {
  project: Models.ProjectPDF;
  printerApproveReject: (params: Api.IPrinterApproveReject) => void;
}

const ProjectRow: FunctionComponent<IProps> = ({ project, printerApproveReject }) => {
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(ClaimType.Locality, passportContext.claims);

  const handleApproveReject = (acceptReject: boolean) => {
    if (!passportContext.bearerToken || !cultureClaim) {
      return;
    }

    printerApproveReject({
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      projectPDFID: project.projectPDFID,
      acceptReject,
    });
  };

  return (
    <div className="project-item">
      <div className="project-item__container">
        <div className="project-item__content">
          <div className="project-item__title">
            <span className={`flag ${project.cultureCode}`}></span>
            <a href={project.pdfUrl} target="_blank" rel="noopener noreferrer">
              {project.pdfUrl}
            </a>
          </div>
          <div className="project-item__date">{project.user.userFirstName} {project.user.userLastName}</div>
        </div>
        <div className="project-item__controls">
          <div className="project-item__button project-item__add">
            <Verified />
            <span onClick={() => handleApproveReject(true)}>Approve</span>
          </div>
          <div className="project-item__button project-item__remove">
            <Close />
            <span onClick={() => handleApproveReject(false)}>Reject</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  printerApproveReject: (params: Api.IPrinterApproveReject) => adminActionCreators.printerApproveReject(params),
};

export default connect(null, mapDispatchToProps)(ProjectRow);
