export const FETCH_TRANSLATIONS = 'TRANSLATIONS/FETCH_TRANSLATIONS';
export const FETCH_TRANSLATIONS_SUCCESS = 'TRANSLATIONS/FETCH_TRANSLATIONS_SUCCESS';
export const FETCH_TRANSLATIONS_ERROR = 'TRANSLATIONS/FETCH_TRANSLATIONS_ERROR';

export interface FetchTranslations {
  type: typeof FETCH_TRANSLATIONS;
}

export interface FetchTranslationsSuccess {
  type: typeof FETCH_TRANSLATIONS_SUCCESS;
  payload: any;
}

export interface FetchTranslationsError {
  type: typeof FETCH_TRANSLATIONS_ERROR;
  payload: {
    error: string;
  };
}

export type TranslationActionTypes = FetchTranslations | FetchTranslationsSuccess | FetchTranslationsError;
