import { States } from '@core/types';
import { AppActionTypes, SET_NAVIGATION } from './actions';
import { AppEnums } from '@core/enums';

const initialState: States.IAppState = {
  activeNavigation: AppEnums.Navigation.None,
  cultures: [
    {
      cultureName: 'English',
      cultureCode: 'en-GB',
    },
    {
      cultureName: 'French',
      cultureCode: 'fr-FR',
    },
    {
      cultureName: 'European',
      cultureCode: 'en-TT',
    },
    {
      cultureName: 'German',
      cultureCode: 'de-DE',
    },
  ],
};

function appReducer(state: States.IAppState = initialState, action: AppActionTypes): States.IAppState {
  switch (action.type) {
    case SET_NAVIGATION: {
      const newState = { ...state };

      newState.activeNavigation = action.payload;

      return newState;
    }

    default:
      return state;
  }
}

export default appReducer;
