import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';

interface IProps {
  isLoading: boolean;
  message?: string;
}

const LoaderWrapper: FunctionComponent<IProps> = ({ isLoading, message, children }) => {
  const [showLoader, setShowLoader] = useState(false);


  useEffect(() => {
    if (isLoading === false) {
      setShowLoader(false);
      return;
    }

    const handle = window.setTimeout(() => setShowLoader(true), 350);

    return () => window.clearTimeout(handle);
  }, [isLoading]);

  if (isLoading && showLoader) {
    return (
      <Fragment>
        <div className="loader">
        </div>
        {message && (
          <p>{message}</p>
        )}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {children}
    </Fragment>
  );
};

export default LoaderWrapper;
