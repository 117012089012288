import React from 'react';
import { IconConfirmation } from '@components/icons';
import { AuthenticationRoutes } from '@components/routing';
import { Link } from 'react-router-dom';

const AwaitingApproval = () => (
  <div className="form__inner form__inner--confirmation">
    <div className="confirmation">
      <IconConfirmation />
      <h2 className="confirmation__title">Thank You</h2>
      <p className="confirmation__text">
        You account has been sent for approval. We will be in touch with you shortly.
      </p>
      <p><Link to={AuthenticationRoutes.login.path}>Back to login</Link></p>
    </div>
  </div>
);

export default AwaitingApproval;
