import { Dispatch, AnyAction } from 'redux';
import { Api, Models } from '@core/types';
import {
  FETCH_NAVIGATION,
  FETCH_NAVIGATION_ERROR,
  FETCH_NAVIGATION_SUCCESS,
  FETCH_PROJECT_PRODUCTS,
  FETCH_PROJECT_PRODUCTS_ERROR,
  FETCH_PROJECT_PRODUCTS_SUCCESS,
  SEARCH_PRODUCTS,
  SEARCH_PRODUCTS_ERROR,
  SEARCH_PRODUCTS_SUCCESS,
  IMPORT_BY_ARTICLE_NUMBER,
  IMPORT_BY_ARTICLE_NUMBER_ERROR,
  IMPORT_BY_ARTICLE_NUMBER_SUCCESS,
} from './actions';
import { ApiService, ProductService } from '@core/services';
import { CategoryEnums } from '@core/enums';
import { fetchProject } from '../projects/actioncreators';
import { ThunkDispatch } from 'redux-thunk';
import { projectActionCreators } from '../projects';

export const fetchProjectProducts = (params: Api.IFetchProductsRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_PROJECT_PRODUCTS,
  });

  
  if (params.variantAgilityIDs?.length === 0) {
    // dispatch an empty response, we don't want to do an api call
    return dispatch({
      type: FETCH_PROJECT_PRODUCTS_SUCCESS,
      payload: {
        categories: [],
      },
    });
  }

  // Do the api request
  const response = await ApiService.request<Models.Category[]>({
    controller: 'product',
    method: Api.Method.POST,
    slug: 'get-filtered-products',
    bearerToken: params.bearerToken,
    params: {
      ...params,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: FETCH_PROJECT_PRODUCTS_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response
  dispatch({
    type: FETCH_PROJECT_PRODUCTS_SUCCESS,
    payload: {
      categories: response.data || [],
    },
  });
};

async function fetchNavigationItem(params: Api.IFetchProductsRequest) {
  const response = await ApiService.request<Models.Category[]>({
    controller: 'product',
    method: Api.Method.POST,
    slug: 'get-filtered-products',
    bearerToken: params.bearerToken,
    params: {
      ...params,
    },
  });

  return response;
}

export const fetchNavigation = (bearerToken: string, cultureCode: string) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: FETCH_NAVIGATION,
  });

  // Fetch all of the navigation tiems
  const [cordlessResponse, cordedResponse, m18Response, m12Response] = await Promise.all([
    fetchNavigationItem({ bearerToken, cultureCode, categoryAgilityIDs: [CategoryEnums.AgilityIDs.Cordless] }),
    fetchNavigationItem({ bearerToken, cultureCode, categoryAgilityIDs: [CategoryEnums.AgilityIDs.Corded] }),
    fetchNavigationItem({ bearerToken, cultureCode, systems: [CategoryEnums.SystemIDs.m18] }),
    fetchNavigationItem({ bearerToken, cultureCode, systems: [CategoryEnums.SystemIDs.m12] }),
  ]);

  const errors: Api.IApiError[] = [];

  // Cordless Errors
  if (cordlessResponse.errors.length) {
    errors.push(...cordlessResponse.errors);
  }

  // Corded Errors
  if (cordedResponse.errors.length) {
    errors.push(...cordedResponse.errors);
  }

  // M18 Errors
  if (m18Response.errors.length) {
    errors.push(...m18Response.errors);
  }

  // M12 Errors
  if (m12Response.errors.length) {
    errors.push(...m12Response.errors);
  }

  if (errors.length > 0) {
    return dispatch({
      type: FETCH_NAVIGATION_ERROR,
      payload: errors,
    });
  }

  // dispatch the final response data
  return dispatch({
    type: FETCH_NAVIGATION_SUCCESS,
    payload: {
      cordless: cordlessResponse.data && cordlessResponse.data[0],
      corded: cordedResponse.data && cordedResponse.data[0],
      // TODO: change this to do a lookup on agility ID
      m18: m18Response.data && m18Response.data[1],
      m12: m12Response.data && m12Response.data[0],
    },
  });
};

export const searchProducts = (params: Api.IFetchProductsRequest) => async (dispatch: Dispatch) => {
  // Initial action to trigger loaders
  dispatch({
    type: SEARCH_PRODUCTS,
  });

  // Do the api request
  const response = await ApiService.request<Models.Category[]>({
    controller: 'product',
    method: Api.Method.POST,
    slug: 'get-filtered-products',
    bearerToken: params.bearerToken,
    params: {
      ...params,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: SEARCH_PRODUCTS_ERROR,
      payload: response.errors,
    });
  }

  // dispatch the final response
  dispatch({
    type: SEARCH_PRODUCTS_SUCCESS,
    payload: {
      categories: response.data || [],
    },
  });
};

export const importByArticleNumber = (params: Api.IFetchProductsRequest) => async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
  // Initial action to trigger loaders
  dispatch({
    type: IMPORT_BY_ARTICLE_NUMBER,
  });

  // Do the api request
  const response = await ApiService.request<Models.Category[]>({
    controller: 'product',
    method: Api.Method.POST,
    slug: 'get-filtered-products',
    bearerToken: params.bearerToken,
    params: {
      ...params,
    },
  });

  // Dispatch any errors
  if (!response.success || response.errors.length > 0) {
    return dispatch({
      type: IMPORT_BY_ARTICLE_NUMBER_ERROR,
      payload: response.errors,
    });
  }

  const flattenedResults = ProductService.flatten(response.data || []);

  const unmatchedProducts = ProductService.findMissingArticleNumbers(flattenedResults, params.articleNumbers || []);

  // dispatch the final response
  dispatch({
    type: IMPORT_BY_ARTICLE_NUMBER_SUCCESS,
    payload: {
      importResults: flattenedResults,
      importErrors: unmatchedProducts,
    },
  });
};
