import React, { FunctionComponent, useState, Fragment } from 'react';
import { usePassportContext } from '@passport/hooks';
import { Api, States, Models } from '@core/types';
import { connect } from 'react-redux';
import { projectActionCreators } from '@redux/projects';
import { Modal } from '@components/shared';
import { ClaimType } from '@passport/enums';
import PdfSettings from './PdfSettings';
import { ProjectEnums } from '@core/enums';
import { MdClose } from 'react-icons/md';

interface IProps {
  app?: States.IAppState;
  project?: States.IProjectState;
  generatePDF: (params: Api.IGeneratePDFRequest) => void;
  sendForApproval: (params: Api.ISendForApprovalRequest) => void;
  setPDFModal: (params: Models.SetPDFModal) => void;
  downloadPDF: (url: string) => void;
  clearPDF: () => void;
}

const ProjectDetailFooter: FunctionComponent<IProps> = ({ app, project, generatePDF, sendForApproval, setPDFModal, clearPDF, downloadPDF }) => {
  const { passportContext, getClaim } = usePassportContext();
  const cultureClaim = getClaim(ClaimType.Locality, passportContext.claims);

  const handleShowGeneratePdf = (type: ProjectEnums.PDFType) => {
    setPDFModal({
      isOpen: true,
      pdfType: type,
    });
  };

  const handleCloseGeneratePdf = () => {
    // Reset the PDF modal
    setPDFModal({
      isGenerating: false,
      isOpen: false,
      isSendingForApproval: false,
      pdfType: ProjectEnums.PDFType.Unset,
    });

    // Clear the PDF values
    clearPDF();
  };

  const handleGeneratePDF = (cultureCode: string, size: ProjectEnums.PDFSize) => {
    if (!project || project.currentProject === null || !passportContext.bearerToken) {
      return;
    }

    generatePDF({
      bearerToken: passportContext.bearerToken,
      projectGUID: project.currentProject.projectGUID,
      cultureCode,
      size,
    });
  };

  if (!project || project.currentProject === null || project.currentProject.products.length === 0 || !app) {
    return null;
  }

  const handleDownload = () => {
    if (!project.projectPDF) {
      clearPDF();
      return;
    }

    downloadPDF(project.projectPDF.pdfUrl);
  };

  const handleSendToApproval = () => {
    if (!project || project.currentProject === null || !passportContext.bearerToken || !cultureClaim || !project.projectPDF) {
      return;
    }

    sendForApproval({
      bearerToken: passportContext.bearerToken,
      cultureCode: cultureClaim.value,
      projectPDFID: project.projectPDF.projectPDFID,
    });
  };

  return (
    <Fragment>
      <Modal
        visible={project.pdfModal.isOpen}
        onClose={handleCloseGeneratePdf}
        showClose={!project.pdfModal.isGenerating}>
        <PdfSettings
          app={app}
          project={project}
          onSelectedSettings={handleGeneratePDF}
          onSubmit={handleSendToApproval}
          onDownload={handleDownload}
          isDownload={project.pdfModal.pdfType === ProjectEnums.PDFType.Download}
        />
      </Modal>
      <div className="selected-products__footer">
        <button className="btn btn--inline selected-products__download" onClick={() => handleShowGeneratePdf(ProjectEnums.PDFType.Download)}>
          Download
      </button>
        <button className="btn btn--inline selected-products__download" onClick={() => handleShowGeneratePdf(ProjectEnums.PDFType.SendToPrinters)}>
          Send To Printers
      </button>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: States.IRootState) => ({
  app: state.app,
  project: state.project,
});

const mapDispatchToProps = {
  generatePDF: (params: Api.IGeneratePDFRequest) => projectActionCreators.generatePDF(params),
  sendForApproval: (params: Api.ISendForApprovalRequest) => projectActionCreators.SendForApproval(params),
  setPDFModal: (params: Models.SetPDFModal) => projectActionCreators.setPDFModal(params),
  downloadPDF: (url: string) => projectActionCreators.downloadPDF(url),
  clearPDF: () => projectActionCreators.clearPDF(),
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailFooter);
