import React, { FunctionComponent, Fragment } from 'react';

interface IProps {
  message: string;
  center?: boolean;
}

const Error: FunctionComponent<IProps> = ({ message, children, center = false }) => (
  <Fragment>
    <p className={`error ${center ? 'align--center' : ''}`}>{message} {children}</p>
  </Fragment>
);

export default Error;
